import React, { useEffect, useState } from "react";
import { TiArrowLeft } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import logonew from '../../assets/images/logo.svg';
import InputField from "../../components/ui/Input";
import OTPInput from "../../components/ui/Otp";
import { DefaultMessages, ErrorMessages } from "../../constants/Labels";
import { forgotPassword, resendOtp, validateOTP } from "../../services/AuthService";
import { showToastError, showToastSuccess } from "../../utils/common/ToastMessages";
import { isNotEmptyString, isValidEmail, notAllowSpaces } from "../../utils/common/Validation";


interface Errors {
  email?: string;
  otpError?: string;
}

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [errors, setErrors] = useState<Errors>({});
  const [showOtp, setShowOtp] = useState<boolean>(false);
  const [enteredOTP, setEnteredOTP] = useState<string>("");
  const [showPasswordSetUp, setShowPasswordSetUp] = useState<boolean>(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail') || '';
    setEmail(userEmail);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setEmail(value);
  };
  // Submit handler
  const submitEmail = () => {
    // setShowOtp(true);
    const newErrors: Errors = {};
    if (!isNotEmptyString(email)) {
      newErrors.email = ErrorMessages.EmailRequired;
    } else if (!isValidEmail(email)) {
      newErrors.email = ErrorMessages.InvalidEmail;
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const object = {
        email: email,
      };
      forgotPassword(object).then((response: any) => {
        if (response.status) {
          showToastSuccess(DefaultMessages?.ForgotPassword);
          setShowOtp(true);
        } else {
          showToastError(response?.message);
        }
      })
        .catch((error: any) => {
          showToastError(ErrorMessages.SomethingWrong);
          setShowOtp(false);
        });
    }
  };
  // To navigate to sign in
  const navToSignin = () => {
    navigate('/signin');
  };
  // Handle change for otp
  const handleOTPChange = (otp: string) => {
    setEnteredOTP(otp);
  };

  const verifyOTP = () => {
    const newErrors: Errors = {};
    if (!isNotEmptyString(enteredOTP)) {
      newErrors.otpError = ErrorMessages.otpError;
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const obj = {
        email: email,
        otp: enteredOTP,
      };
      validateOTP(obj).then((response: any) => {
        if (response.status) {
          showToastSuccess(response?.message);
          setShowPasswordSetUp(true);
          const userId = response.userId;
          navigate('/resetPassword', { state: { userId } });
        } else {
          showToastError(response?.message);
        }
      })
        .catch((error: any) => {
          showToastError(ErrorMessages.SomethingWrong);
          setShowOtp(false);
        });
    } else {

    }

  };
  // Function for resent otp
  const handleResendOTP = () => {
    setResendDisabled(true);
    setResendTimer(60);
    startTimer();
    const object = {
      email: email,
    };
    resendOtp(object).then((response: any) => {
      if (response.status) {
        showToastSuccess(response?.message);
        setShowOtp(true);
      } else {
        showToastError(response?.message);
      }
    })
      .catch((error: any) => {
        showToastError(ErrorMessages.SomethingWrong);
      });
  }
  // Start the resend timer
  const startTimer = () => {
    let startTime = Date.now(); // Get the current time
    const timer = setInterval(() => {
      const elapsedTime = Math.floor((Date.now() - startTime) / 1000); // Calculate elapsed time in seconds
      const remainingTime = 60 - elapsedTime; // Calculate remaining time
      setResendTimer(remainingTime); // Update the timer

      if (remainingTime <= 0) {
        // Timer finished, enable resend button
        setResendDisabled(false);
        clearInterval(timer);
      }
    }, 1000);
  };
  // TO avoid empty spaces cursor moving
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    notAllowSpaces(event.currentTarget.value, event);
  };

  return (
    <div className="container mxw-100">
      <div className="row d-flex align-items-center justify-content-center">
        <div className="col-xl-5 col-lg-6 col-sm-12">
          <div className="vh-100 d-flex align-items-center justify-content-center w-100">
            {!showOtp ? (
              <div className="inner w-100 shadow-sm p-4 border rounded-md bg-white">
                <img src={logonew} className="d-flex mx-auto w-[120px] mb-[34px]"
                  alt="StratPilot" />

                <h3 className="mb-0 fw-bold text-center">Forgot Password?</h3>

                <p className="text-center mb-0">
                  Enter your email address you used to sign in to reset password
                </p>
                <div className="w-100 text-start mt-4 mb-0">
                  <InputField
                    name="email"
                    value={email}
                    label="Email"
                    placeholder="example@gmail.com"
                    onChange={handleChange}
                    error={errors.email}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="d-flex justify-content-end mt-4">
                  <div className="d-inline-flex align-items-center text-primary fw-bold cursor-pointer"


                    onClick={navToSignin}
                  >
                    <TiArrowLeft size={25} />
                    <span className="d-flex align-items-center flex-column justify-content-center pt-1">Back to Sign In</span>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-1 mb-2">
                  <button
                    type="button"
                    className="btn btn-primary w-100 d-flex mx-auto justify-content-center mb-0 text-white font-size-14 py-2 h-[40px]"
                    onClick={submitEmail}
                    disabled={!isValidEmail(email)}
                  >
                     <span className="h-[20px] d-inline-block mt-[2px]">Reset Password</span>
                  </button>
                </div>
                {/* <div className="or-text position-relative my-2 text-center">
                  <span className="d-inline-block m-0 text-gray bg-white px-2 position-relative z-9">OR</span>
                </div> */}

              </div>
            ) : (
              <div className="inner w-100 shadow-sm p-4 border rounded-md bg-white">
                <img src={logonew} className="d-flex mx-auto w-[120px] mb-[34px]"
                  alt="StratPilot" />

                <div className="d-flex align-items-center justify-content-center mt-4">
                  <h3 className="mb-0  fw-bold">Check your Email</h3>
                </div>
                <div>
                  <p className="text-center">
                    We sent a reset OTP to <span className="font-weight-bold fw-bold text-primary">{email}</span>, please enter the 5-digit code mentioned in the email.
                  </p>
                </div>
                <div className="w-100 otp-inner-label text-start mb-3">
                  <label className="text-dark fw-bold mb-1">Enter OTP</label>
                  <OTPInput onOTPChange={handleOTPChange} error={errors.otpError} />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="d-inline-block m-0  bg-white  position-relative z-9">Haven't got the email yet?</span>
                  <div className="d-flex align-items-center fw-bold text-primary"

                    onClick={handleResendOTP}>

                    {!resendDisabled ?
                      (
                        <><TiArrowLeft size={25} /><span className="d-flex align-items-center cursor-pointerflex-column justify-content-center cursor-pointer pt-1">
                          Resend OTP
                        </span></>
                      ) : (
                        <span className="d-flex align-items-center flex-column justify-content-center pt-1 fs-md fw-bold">
                          {resendTimer > 0 ? `${resendTimer} sec` : ""}
                        </span>
                      )}
                  </div>
                </div>
                <div className="d-flex justify-content-center mb-1 mt-2">
                  <button
                    type="button"
                    className="btn btn-primary w-100 d-flex mx-auto justify-content-center mb-0 text-white font-size-14 py-2 h-[40px]"
                    onClick={verifyOTP}
                  >
                    <span className="h-[20px] d-inline-block mt-[2px]">Verify Code</span>
                  </button>
                </div>

              </div>

            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
