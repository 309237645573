import axios from "axios";
import { httpService } from "./api/Api";


export const clearSession = () => {
    localStorage.clear();
}
export const getLoginUserID = () => {
    // Retrieve user details from local storage
    const userDetails = localStorage.getItem('UserDetails');
    return userDetails ? JSON.parse(userDetails) : '';
}
// To update password
export const resetPassword = (obj: any) => {
    return httpService.post('User/AdminResetPassword', obj);
}
// To send otp email
export const forgotPassword = (obj: any) => {
    return httpService.post('User/AdminForgotPassword', obj);
}
// To validate entered otp
export const validateOTP = (obj: any) => {
    return httpService.post('User/AdminOTPValidation', obj);
}
// Custum login
export const custumLogin = (obj: any) => {
    return httpService.post('User/ValidateUser', obj);
}
// To set password for inviteUser
export const ActivateInviteUser = (obj: any) => {
    return httpService.post('User/ActivateInviteUser', obj);
}
// Get auth Token Method
export const getAuthTOken = () => {
    localStorage.getItem('authToken')
}

// UPDATE PROFILE APIS
export const UpdatePassword = (obj: any) => {
    return httpService.post('User/ChangeUserPassword', obj);
}

export const UpdateUserProfile = (obj: any) => {
    return httpService.post('UserManagement/UpdateUserData', obj);
}


//  DASHBOARD APIS

// TO get Dashboard users api 
export const getUserGraphsData = () => {
    return httpService.get('dashboard/GetUserStatisticsData');
}
// TO get Dashboard Trending topics api 
export const GetTrendingTopics = (obj: any) => {
    return httpService.post('dashboard/GetTrendingTopics', obj);
}
export const resendOtp = (obj: any) => {
    return httpService.post('User/MResendOTP', obj);
}
// TO get Dashboard GetDashboardWidgetCounts api 
export const GetDashboardWidgetCounts = (obj: any) => {
    return httpService.post('dashboard/GetDashboardWidgetCounts', obj);
}
// TO get Dashboard users api 
export const GetTrendingResources = (obj: any) => {
    return httpService.post('dashboard/GetTrendingResources', obj);
}
// TO get conversations count
export const GetConversationCount = (obj: any) => {
    return httpService.postConversation('get_conversationv1', obj);
}

export const timelineChart = (obj: any) => {
    return httpService.post('Resource/GetTimelineChart', obj);
}
export const getPieChart = () => {
    return httpService.get('Resource/GetUserEngagementChart');
}