import { Pagination } from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createStaticRanges, DateRangePicker, defaultStaticRanges } from "react-date-range";
import Dropdown from 'react-dropdown';
import { BsFilterLeft } from "react-icons/bs";
import { FaEllipsisV } from "react-icons/fa";
import { PiArrowsDownUpFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import DeleteConformation from "../../components/common/modals/DeleteConformation";
import SearchInput from "../../components/ui/SearchInput";
import { ErrorMessages } from "../../constants/Labels";
import { deletePost, getCommunityList, getPostDetailsByID, hidePost } from "../../services/CommunityService";
import { formatDates } from "../../utils/common/Format";
import { showToastError, showToastSuccess } from "../../utils/common/ToastMessages";
import CommunityMetrics from "./CommunityMetrics";
import ViewCommunityPost from "./ViewCommunityPost";

export default function Community() {
    const navigate = useNavigate()
    const [startDate, setStartDate] = useState<any>('');
    const [endDate, setEndDate] = useState<any>('');
    const [showCalendar, setShowCalendar] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectedOption, setSelectedOption] = useState<any>('');
    const [dates, setDate] = useState<any>()
    const recordsPerPage = 10; // Number of records per page
    const [totalCount, setTotalCount] = useState(0);
    const [orderBy, setOrderBy] = useState<any>('Asc');
    const [sortValue, setSortValue] = useState('')
    const [selectedListType, setSelectedListType] = useState('postLog')
    const [selectedMetricsSortOption, setSelectedMetricsSortOption] = useState<any>('');
    const [communityData, setcommunityData] = useState([]);
    const [actionIndex, setActionIndex] = useState<number | null>(null);
    const dropdownRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [showMetricsCalender, setShowMetricsCalender] = useState(false)
    const [deleteItem, setDeleteItem] = useState()
    const [deletePopup, setShowDeletePopup] = useState(false)
    const [postDetials, setShowPostDetials] = useState(false)
    const [selectedItem, setSelectedItem] = useState()
    const [searchTerm, setSearchTerm] = useState('')
    const [orderBytopicNames, setOrderBytopicNames] = useState('Asc')
    const [orderBytitle, setOrderBytitle] = useState('Asc')
    const [OrderByFlagged, setOrderByFlagged] = useState('Asc')
    const [orderByUpvotes, setOrderByUpvotes] = useState('Asc')
    const [orderByDate, setorderByDate] = useState('Asc')
    const [tempStartDate, setTempStartDate] = useState<any>(null);
    const [tempEndDate, setTempEndDate] = useState<any>(null);


    // To clear activity popup
    const clearActivityPopup = useCallback(() => {
        setActionIndex(null);
    }, []);
    // handle clikoutside action popup
    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (dropdownRefs.current.some((ref: any) => ref && ref.contains(event.target as Node))) {
            return;
        }
        clearActivityPopup();
    }, [clearActivityPopup]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);
    // To open actions 
    const OpenActionArea = (index: number) => {
        setActionIndex(index);
    };
    // Dropdown values
    const options = [
        { value: '0', label: "All" },
        { value: '1', label: "Popular" },
        { value: '2', label: "ByDate" },
        { value: '3', label: "Most Bookmarked" }
    ];
    const handleChange = (event: any) => {
        const value = event.label;
        setPageNumber(1);
        setSearchTerm('')
        if (value === 'ByDate') {
            setShowCalendar(true);
            setSelectedOption(value);
            setTempStartDate(new Date())
            setTempEndDate(new Date())
        } else {
            setSelectedOption(value);
            setShowCalendar(false);
            setStartDate('');
            setEndDate('');
        }
    }

    const handleSelect = (date: any) => {
        const startDate = moment(date.selection.startDate).startOf('day').format('YYYY-MM-DD');
        const endDate = moment(date.selection.endDate).startOf('day').format('YYYY-MM-DD');
        setDate(date)
        // setStartDate(startDate);
        // setEndDate(endDate);
        setTempStartDate(startDate);
        setTempEndDate(endDate);
    };

    const selectionRange = {
        startDate: moment(tempStartDate).toDate(),
        endDate: moment(tempEndDate).toDate(),
        key: 'selection',
    };
    // To add custum filter to calender
    const customStaticRanges = createStaticRanges([
        ...defaultStaticRanges,
        {
            label: 'Custom',
            range: () => ({
                startDate: new Date(),
                endDate: new Date(),
            }),
            isSelected(range: any) {
                return range.custom;
            },
        },
    ]);
    // cancel the selected dates
    const resetTime = () => {
        setStartDate(" ")
        setEndDate(" ")
        setShowCalendar(false)
    }
    // TO close the calender when dropdown clicked
    const handleDropdownClick = (event: any) => {
        // Ensure this only happens when clicking the dropdown, not the calendar
        if (!event.target.closest('.smart-date-picker')) {
            if (showCalendar || showMetricsCalender) {
                setShowCalendar(false);
                setShowMetricsCalender(false)
                setStartDate('');
                setEndDate('');
            }
        }
    };
    // custum selected dates submit
    const setCustumDates = () => {
        setStartDate(tempStartDate);
        setEndDate(tempEndDate);
        setShowCalendar(false)

    }
    // Pagination
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageNumber(value);
    };
    const totalPages = Math.ceil(totalCount / recordsPerPage);

    // To send the utcoff set time
    const getUTCOffset = () => {
        const date = new Date();
        const offsetInMinutes = date.getTimezoneOffset();
        const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60);
        const offsetMinutes = Math.abs(offsetInMinutes) % 60;
        const sign = offsetInMinutes <= 0 ? '+' : '-';
        return `${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;
    };
    // TO get the list data
    const getCommunityListData = () => {
        const utcOffset = getUTCOffset()
        let obj = {
            "pageNumber": pageNumber,
            "pageSize": 10,
            "search": '',
            'filterBy': selectedOption,
            "startDate": startDate ? startDate : '',
            "endDate": endDate ? endDate : '',
            "sortColumnName": sortValue,
            "sortColumnDirection": orderBy,
            "category": searchTerm,
            "utcOffset": (startDate && endDate !== '' ? utcOffset : ''),

        }
        getCommunityList(obj).then((response: any) => {
            if (response?.status == true) {
                setcommunityData(response.postList[0].tipsLog)
                setTotalCount(response.postList[0].TotalCount);
            } else {
                showToastError(response?.message)
            }

        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong)
        })
    }
    useEffect(() => {
        getCommunityListData();
    }, [pageNumber, orderBy, selectedOption, searchTerm, startDate, endDate]);

    // To set tab options
    const listMethod = (listMethod: any) => {
        setSelectedListType(listMethod)
    }
    // Checkbox toggle handler
    const handletoggleCheck = (event: any, postId: any) => {
        let obj = {
            "postId": postId,
            "isHide": !event.target.checked
        }
        hidePost(obj).then((res: any) => {
            if (res.status) {
                showToastSuccess(res?.message)
                getCommunityListData()
                return
            }
            showToastError(res?.message)

        }).catch(() => {
            showToastError("Error while hide/ showing the post")
        })
    }
    // Metrics filters
    const metricsFilters = [
        { label: 'All', value: 'All', id: 0 },
        { label: 'ByDate', value: 'ByDate', id: 1 },
    ]
    // Metrics filter handle change
    const metricsHandleChange = (event: any) => {
        const value = event.value
        setSelectedMetricsSortOption(value); // Set the entire event object
        if (value === 'ByDate') {
            setShowMetricsCalender(true);
            setTempStartDate(new Date())
            setTempEndDate(new Date())
        } else {
            setShowMetricsCalender(false);
            setStartDate('');
            setEndDate('');
        }
    }
    // custum selected dates for metrics submit
    const setCustumDatesMetrics = () => {
        let SDates = moment(dates?.selection?.startDate).startOf('day').format('YYYY-MM-DD');
        let EDates = moment(dates?.selection?.endDate).startOf('day').format('YYYY-MM-DD');
        setStartDate(SDates);
        setEndDate(EDates);
        setShowMetricsCalender(false)
    }
    // To navigate to edit tip
    const navToEditPost = (postId: any) => {
        navigate('/community/EditCommunityPosts', { state: postId })
    }
    // Delete conform popup open 
    const deleteConform = (item: any) => {
        setDeleteItem(item)
        setShowDeletePopup(true)
    }

    // success handle for delete
    const successHandle = () => {
        let obj = {
            "postId": deleteItem
        }
        deletePost(obj).then((response: any) => {
            if (response.status) {
                showToastSuccess(response.message)
                getCommunityListData();
                setShowDeletePopup(false)
            } else {
                showToastSuccess(response.message)
            }

        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong)
            setShowDeletePopup(false)
        })
    }
    // To open edit popup and get data by id api
    const viewPost = (itemId: any) => {
        clearActivityPopup()
        getPostDetailsByID(itemId).then((response: any) => {
            if (response.status) {
                setSelectedItem(response.postDetails[0])
                setShowPostDetials(true)
            } else {
                showToastError(response?.message)
            }

        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong)
        })
    }
    // Searchfunctionality
    const handleSearch = (value: any) => {
        setSearchTerm(value);
    };
    //set sorting Order of all table values  
    const toggleOrder = (type: 'upvotes' | 'date' | 'topicNames' | 'title' | 'flagged') => {
        if (type === 'upvotes') {
            const newOrder = orderByUpvotes === 'Asc' ? 'Desc' : 'Asc';
            setOrderByUpvotes(newOrder);
            setSortValue('upvotesCount')
            setOrderBy(newOrder)
        }
        else if (type === 'date') {
            const newOrder = orderByDate === 'Asc' ? 'Desc' : 'Asc';
            setorderByDate(newOrder);
            setSortValue('createdDate')
            setOrderBy(newOrder)
        }
        else if (type === 'topicNames') {
            const newOrder = orderBytopicNames === 'Asc' ? 'Desc' : 'Asc';
            setOrderBytopicNames(newOrder);
            setSortValue('topicNames')
            setOrderBy(newOrder)
        }
        else if (type === 'title') {
            const newOrder = orderBytitle === 'Asc' ? 'Desc' : 'Asc';
            setOrderBytitle(newOrder);
            setSortValue('title')
            setOrderBy(newOrder)
        }
        else if (type === 'flagged') {
            const newOrder = OrderByFlagged === 'Asc' ? 'Desc' : 'Asc';
            setOrderByFlagged(newOrder);
            setSortValue('flaggedCount')
            setOrderBy(newOrder)
        }

    };


    return (
        <div className="container-fluid  px-3">
            {!postDetials &&
                <div>
                    <div className="d-flex justify-content-between align-items-center shadow-sm bg-white rounded border p-3" >
                        <h3 className="fw-bold mb-0" >Community </h3>
                    </div>
                    <div className="d-flex justify-content-between  my-3 bg-white rounded p-2 resourcelist-tab-panel">
                        <div className="d-flex justify-content-end mt-0 align-items-center">
                            <div className={` p-3 fs-sm py-2 border text-center me-2 w-[160px] cursor-pointer  rounded ${selectedListType == 'postLog' ? 'bg-secondary text-white shadow-sm' : ' fw-normal'}`} onClick={() => { listMethod('postLog'); setPageNumber(1); setSelectedOption(''); setSearchTerm(''); setShowCalendar(false) }}> Post Log</div>
                            <div className={`p-3  fs-sm py-2 border text-center w-[160px] cursor-pointer rounded  ${selectedListType === 'metrics' ? ' bg-secondary text-white shadow-sm' : ' fw-normal'}`} onClick={() => { listMethod('metrics'); setSortValue(''); setSelectedMetricsSortOption(''); setShowMetricsCalender(false) }} > Metrics</div>
                        </div>
                        {selectedListType == 'postLog' ? <>
                            <div className="d-flex justify-content-end mt-0 align-items-center">
                                <div className="me-2  smart-dropdown">
                                    <SearchInput label='Search' onChange={handleSearch} value={searchTerm} />
                                </div>
                                <div className="position-relative smart-dropdown" onClick={handleDropdownClick}>
                                    <Dropdown options={options} onChange={handleChange} value={selectedOption} placeholder="Filter" />
                                    <BsFilterLeft className="position-absolute start-0 top-50 translate-y-[-50%] text-gray ms-2" />
                                    <div style={{ zIndex: '99999' }} className="position-fixed smart-date-picker deep-shadow bg-white p-0 rounded border mt-[6px]">
                                        {showCalendar && (
                                            <div className="" style={{ display: 'contents' }}>
                                                <DateRangePicker
                                                    ranges={[selectionRange]}
                                                    onChange={handleSelect}
                                                    moveRangeOnFirstSelection={false}
                                                    staticRanges={customStaticRanges}
                                                    inputRanges={[]} // Remove predefined input ranges
                                                    showMonthArrow={true} />
                                                <div className="d-flex justify-content-center position-absolute bottom-0 end-0 py-2  pr-3">
                                                    <button className="btn btn-primary-outline py-1 h-[30px]" onClick={resetTime}><span className=" d-inline-block leading-6">Cancel</span></button>
                                                    <button className="btn btn-primary text-white py-1  ms-2  h-[30px]" onClick={setCustumDates}><span className="d-inline-block  leading-6">Submit</span></button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </> : <div className="smart-dropdown position-relative" onClick={handleDropdownClick}>
                            <Dropdown options={metricsFilters} onChange={metricsHandleChange} value={selectedMetricsSortOption} placeholder="Filter" />
                            <BsFilterLeft className="position-absolute start-0 top-50 translate-y-[-50%] text-gray ms-2" />
                            <div style={{ zIndex: '99999' }} className="position-fixed  smart-date-picker deep-shadow bg-white p-0 rounded border mt-[6px]">
                                {showMetricsCalender && (
                                    <div className="" style={{ display: 'contents' }}>
                                        <DateRangePicker
                                            ranges={[selectionRange]}
                                            onChange={handleSelect}
                                            moveRangeOnFirstSelection={false}
                                            staticRanges={customStaticRanges}
                                            inputRanges={[]} // Remove predefined input ranges
                                            showMonthArrow={true}
                                        />
                                        <div className="d-flex justify-content-center position-absolute bottom-0 end-0 py-2  pr-3">
                                            <button className="btn btn-primary-outline py-1 h-[30px] d-inline-block leading-6" onClick={resetTime}>Cancel</button>
                                            <button className="btn btn-primary text-white py-1  ms-2  h-[30px] d-inline-block  leading-6" onClick={setCustumDatesMetrics}>Submit</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        }

                    </div>
                    <div>
                        {selectedListType == 'postLog' &&
                            <><div className="smart-table overflow-x-auto shadow-sm border rounded mxh-calc-321">
                                <table className="table table position-relative my-0">
                                    <thead className="th-pattenblue">
                                        <tr className="fw-bold sticky top-0 z-[9] bg-white">
                                            <th scope="col" className="w-[300px]">
                                                <div onClick={() => toggleOrder('title')} title={orderBytitle === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}>
                                                    <div className="d-flex align-items-center cursor-pointer">Title <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /> </div>
                                                </div>
                                            </th>
                                            <th className="w-[195px]" onClick={() => toggleOrder('topicNames')} title={orderBytopicNames === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}><div className="d-flex align-items-center cursor-pointer text-nowrap">Topic <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /></div></th>
                                            <th scope="col">
                                                <div className='d-flex justify-content-start cursor-pointer align-items-center text-nowrap' onClick={() => toggleOrder('upvotes')}>
                                                    <div className="d-flex align-items-center">Upvotes <span title={orderByUpvotes === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'} className='ms-2'><PiArrowsDownUpFill className="mt-[-4px]" /></span></div>
                                                </div></th>
                                            <th scope="col">
                                                <div className='d-flex justify-content-start cursor-pointer align-items-center text-nowrap' onClick={() => toggleOrder('flagged')}>
                                                    <div className="d-flex align-items-center">Flagged <span title={OrderByFlagged === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'} className='ms-2'><PiArrowsDownUpFill className="mt-[-4px]" /></span></div>
                                                </div></th>
                                            <th scope="col"><div className='d-flex justify-content-start cursor-pointer align-items-center text-nowrap' onClick={() => toggleOrder('date')}>
                                                Date <span title={orderByDate === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'} className='ms-2'><PiArrowsDownUpFill className="mt-[-4px]" /></span>
                                            </div></th>                                            <th scope="col">Action </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {communityData?.length > 0 ?
                                            communityData?.map((item: any, index: any) => (
                                                <tr key={index} className={`${communityData?.length == 1 ? 'moreoptions-position-fixed  ' : ''}`} style={{ color: item.IsHide ? 'grey' : '' }} >
                                                    <td className="cursor-pointer  hover:text-primary" onClick={() => viewPost(item?.postId)} title={item?.title}>{item?.title?.length > 40 ? item?.title?.slice(0, 40) + '...' : item?.title}</td>
                                                    <td title={item?.topicNames?.map((topic: any) => topic?.TopicName).join(', ')}>
                                                        {(() => {
                                                            const topics = item?.topicNames?.map((topic: any) => topic?.TopicName).join(', ');
                                                            const maxLength = 20; // Specify the maximum length of the displayed string
                                                            return topics?.length > maxLength ? `${topics?.slice(0, maxLength)}...` : topics;
                                                        })()}
                                                    </td>
                                                    <td>{item?.upvotesCount}</td>
                                                    <td>{item?.flaggedCount}</td>
                                                    <td>{formatDates(item.createdDate)} </td>


                                                    <td className="actions-column d-flex justify-content-start ">
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input cursor-pointer" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={(e) => { handletoggleCheck(e, item?.postId) }} checked={!item?.IsHide} title={!item?.IsHide ? "Hide Post" : "Show Post"} />
                                                        </div>
                                                        <div className="d-flex justify-content-start align-items-center position-relative" ref={(el: any) => (dropdownRefs.current[index] = el)}>
                                                            <div className="ml-[20px] cursor-pointer">
                                                                <FaEllipsisV className="font-size-10 cursor-pointer" onClick={() => OpenActionArea(index)} />
                                                                <div className={`custom-dropdown-menu bg-white position-absolute border ml-[-108px] w-[120px] shadow-sm mt-[20px] top-0 z-[9] ${actionIndex === index ? '' : 'd-none'}`}>
                                                                    <div className="dropdown-item cursor-pointer py-2" onClick={() => viewPost(item?.postId)}>View</div>
                                                                    <div className="dropdown-item cursor-pointer py-2" onClick={() => navToEditPost(item?.postId)}>Edit</div>
                                                                    <div className="dropdown-item cursor-pointer py-2" onClick={() => deleteConform(item?.postId)}>Delete</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr><td colSpan={12}><h6 className="fw-bold mt-3 d-flex justify-content-center">No data found </h6> </td></tr>}
                                    </tbody>
                                </table>
                            </div>
                                <div className="d-flex justify-content-end mt-2 ">
                                    <div className="bg-white rounded p-2 shadow-sm z-[99] z-intial">
                                        <Pagination
                                            count={totalPages}
                                            variant="outlined"
                                            shape="rounded"
                                            page={pageNumber}
                                            onChange={handlePageChange}
                                            siblingCount={1} // Adjust as necessary
                                            boundaryCount={1} // Adjust as necessary
                                            showFirstButton
                                            showLastButton />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            }

            {postDetials && <ViewCommunityPost selectedItem={selectedItem} setShowPostDetails={setShowPostDetials} setpageNumber={setPageNumber} setFilterValue={setSelectedOption} setSearchTerm={setSearchTerm} getCommunityListData={getCommunityListData} />}
            {selectedListType == 'metrics' && <CommunityMetrics startDate={startDate} endDate={endDate} selectedSortOption={selectedMetricsSortOption} />}
            {deletePopup && <DeleteConformation actionType="Delete" title="Delete Conformation" closeModal={setShowDeletePopup} successHandle={successHandle} />}



        </div>
    )

}