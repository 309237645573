import { useEffect, useRef, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { FaUserLarge } from "react-icons/fa6";
import { IoArrowBackOutline } from "react-icons/io5";
import { TiAttachment } from "react-icons/ti";
import DeleteConformation from "../../components/common/modals/DeleteConformation";
import QiScoreDetails from "../../components/common/modals/QiScoreDetails";
import { ErrorMessages } from "../../constants/Labels";
import { deleteReource } from "../../services/ResourcesService";
import { handleClickOutside } from "../../utils/common/CommonUtility";
import { formatDates } from "../../utils/common/Format";
import { showToastError, showToastSuccess } from "../../utils/common/ToastMessages";


interface PopupProps {
    selectedItem?: any
    setSelectedItem?: any
    setshowResourceDetails?: any
    searchTerm?: any
    setPageNumber?: any
    setFilterValue?: any
    getResurceListData?: any
    setShowEditInViewSc?: any
}


export default function ViewResource({ selectedItem, setSelectedItem, setshowResourceDetails, getResurceListData, setShowEditInViewSc, searchTerm, setFilterValue, setPageNumber }: PopupProps) {
    const QiScoreValues = (selectedItem?.qiScore)
    const [actionIndex, updateActivityIndex] = useState<number | null>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [deletePopup, setShowDeletePopup] = useState(false)
    const [deletingIndex, setDeletingIndex] = useState<any>()

    // Function to clear the activity popup
    const clearActivityPopup = () => {
        updateActivityIndex(null);
    }
    // open actions
    const OpenActionArea = (selectedIndex: number) => {
        updateActivityIndex(selectedIndex);
    };

    useEffect(() => {
        const cleanupClickOutside = handleClickOutside(dropdownRef, clearActivityPopup);
        return () => {
            cleanupClickOutside();
        };
    }, []);
    // To open edit popup
    const editresourceItem = (item: any) => {
        setSelectedItem(item)
        setShowEditInViewSc(true)
        setshowResourceDetails(false);
    }
    // To open delete resource popup
    const deleteItem = (index: any) => {
        setDeletingIndex(index)
        setShowDeletePopup(true)
    }
    // Delete resource success handle
    const deleteSuccessHandle = () => {
        let obj = {
            "resourceId": deletingIndex?.resourceId
        }
        deleteReource(obj).then((response: any) => {
            if (response.status) {
                showToastSuccess(response.message)
                getResurceListData();
                setShowDeletePopup(false)
                setshowResourceDetails(false)
                setPageNumber(1)
                setFilterValue('')
                searchTerm('')
            } else {
                showToastSuccess(response.message)
            }

        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong)
            setShowDeletePopup(false)
        })
    }
    // }
    const renderMedia = (media: any) => {
        if (!media) return null;
        return media.map((content: any) => {
            const { ResourceLink, ResourceLinkID } = content;
            const isImage = /\.(jpg|jpeg|png|gif|webp|bmp|tiff|svg|ico|jfif|pjpeg|pjp|avif)$/i.test(ResourceLink);
            if (isImage) {
                return (
                    <div key={ResourceLinkID} className="col-md-1 px-2 mb-2 ">
                        <div className="w-100 shadow-md">
                            <span className="media-item  d-flex border rounded-sm w-100 align-items-center justify-content-center p-2 h-[60px] w-[60px] flex-60">
                                <img src={ResourceLink} className="w-[30px] h-[30px] d-flex mx-auto object-contain" alt="Media" />
                            </span>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div key={ResourceLinkID} className="media-item">
                        <a href={ResourceLink} target="_blank" rel="noopener noreferrer">
                            {ResourceLink}
                        </a>
                    </div>
                );
            }
        });
    };

    const [QiScorePopup, setShowQiScorePopup] = useState(false)

    return (
        <div >
            <div className='p-4 bg-white border rounded-md mih-calc-85'>
                <div className="  mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <IoArrowBackOutline
                                size={20}
                                className="me-2  cursor-pointer"
                                onClick={() => { setshowResourceDetails(false); searchTerm(''); setFilterValue(''); setPageNumber(1) }}
                            />
                            <h3 className="fw-bold my-0 pt-1">Resource Details</h3>
                        </div>
                        <div>
                            <div className="d-flex justify-content-between align-items-center" ref={dropdownRef}>
                                <FaEllipsisV className="font-size-10 cursor-pointer text-gray" onClick={() => OpenActionArea(selectedItem)} />
                                <div className={`custom-dropdown-menu position-absolute border mt-[60px] shadow-sm bg-white ml-[-109px] rounded-md w-[120px] ${actionIndex === selectedItem ? '' : 'd-none'}`} >
                                    <div className="dropdown-item cursor-pointer" onClick={() => editresourceItem(selectedItem)}>Edit</div>
                                    <div className="dropdown-item cursor-pointer my-0 py-2 leading-4 " onClick={() => deleteItem(selectedItem)}>Delete</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" ms-0">
                    <div className="d-flex justify-content-between align-items-center mb-4 custom-bg-body p-2 rounded-md">
                        <div className="d-flex align-items-center ">
                            <div className="w-[50px] h-[50px] me-3 d-flex justify-content-center align-items-center bg-white p-0 rounded-circle">
                                {selectedItem?.uploadedByImage ? <img src={selectedItem?.uploadedByImage} className="img-fluid p-2" /> : <div className="bg-white  w-100 h-100 rounded-circle d-flex justify-content-center align-items-center"><FaUserLarge size={20} className="me-0" /></div>}

                            </div>
                            <div className="d-block">
                                <label className="text-primary mb-0 ">Uploaded by</label>

                                <h5 className="fw-bold mb-0 ">{selectedItem?.uploadedBy}</h5>
                            </div>
                        </div>
                        <div className="d-flex align-items-start flex-column">
                            <label className="mb-0 fs text-md text-primary">Uploded Date</label>
                            <h5 className="text-dark fw-bold text-base text-gray mb-0">{formatDates(selectedItem?.uploadedDateTime)} </h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="mb-2">
                            <label className="mb-1 text-base text-dark fw-bold">Title:</label>

                            <h5 className="  font-normal mb-1 text-dark text-base ">{selectedItem?.title}</h5>
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className=" w-100  ">
                                <div className="">
                                    <div className="d-block text-dark mb-1 text-base fw-bold">Description:</div>
                                    <div className="border p-3 rounded-md">
                                        <div className="max-h-[200px] overflow-y-auto ">
                                            <span
                                                className="text-base text-dark ul-li-list-normal break-words"
                                                dangerouslySetInnerHTML={{ __html: selectedItem?.description }}
                                            ></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="w-100 ">
                                <div className="w-calc-5px">
                                    <div className="mb-3">
                                        <div className="d-block text-dark mb-0 text-base fw-bold">Topics:</div>
                                        <span className="text-base text-gray">{selectedItem?.topicNames?.map((topic: any) => topic.TopicName).join(', ')}</span>
                                    </div>
                                    <div className="mb-3 d-flex justify-content-start flex-column">
                                        <div className="d-block text-dark mb-0 text-base fw-bold">Sensitive Warning:</div>
                                        <>
                                            <h6 className="text-base text-gray ">
                                                {selectedItem?.sensitiveContentNames?.length > 0 ? selectedItem?.sensitiveContentNames?.map((content: any) => content.SensitiveContentName).join(', ') : 'N/A'}
                                            </h6>
                                        </>
                                    </div>

                                    <div className="mb-0 d-flex justify-content-between">
                                        <div>
                                            <span className="d-block text-dark mb-0 text-base fw-bold">QI Score

                                            </span>
                                            <div className="d-flex mx-auto justify-content-center mt-1 cursor-pointer position-relative group  " >
                                                <div className="text-base text-primary text-center bg-whitesmoke bg-opacity-25 p-2 w-[30px] h-[30px] rounded-circle d-flex align-items-center justify-content-center fw-bold">  {selectedItem?.qiScore[0]?.Score}  </div>
                                                <QiScoreDetails setShowQiScorePopup={setShowQiScorePopup} QiScoreValues={QiScoreValues} />

                                            </div>
                                        </div>
                                        <div className="ms-3">
                                            <span className="d-block text-dark mb-0 text-base fw-bold">Bookmark Count</span>
                                            <div className="d-flex mx-auto justify-content-center mt-1">
                                                <div className="text-base text-primary text-center bg-whitesmoke bg-opacity-25 p-2 w-[30px] h-[30px] rounded-circle d-flex align-items-center justify-content-center fw-bold">  {selectedItem?.BookMarkCount} </div>
                                            </div>
                                        </div>
                                        <div className="ms-3">
                                            <span className="d-block text-dark mb-0 text-base fw-bold">Up Votes</span>
                                            <div className="d-flex mx-auto justify-content-center mt-1">
                                                <div className="text-base text-primary text-center bg-whitesmoke bg-opacity-25 p-2 w-[30px] h-[30px] rounded-circle d-flex align-items-center justify-content-center fw-bold">{selectedItem?.upvotesCount}</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="my-3">
                        <strong className="fw-bold text-dark text-base fw-bold mb-1 d-block">Links:</strong>
                        <div className="d-flex justify-content-start bg-whitesmoke p-2 rounded-md ">
                            {selectedItem?.link ? (
                                <>
                                    <TiAttachment size={20} className="text-primary me-2" />
                                    <a href={selectedItem.link} target="_blank" rel="noopener noreferrer" className="cursor-pointer text-dark">
                                        {selectedItem.link}
                                    </a>
                                </>
                            ) : (
                                'N/A'
                            )}
                        </div>
                    </div>
                    <div className="mb-3">
                        <strong className="fw-bold text-dark text-base text-gray mb-2 d-block">Media:</strong>
                        <div className="rounded-md p-3 border-dotted-solidate">
                            <div className="row mx--2 mxh-312 overflow-y-auto d-flex w-100 justify-content-center align-items-center">
                                {renderMedia(selectedItem?.media) ? renderMedia(selectedItem?.media) : "No images found"}
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">
                        <strong className="fw-bold text-dark text-base text-gray mb-2 d-block">Tags:</strong>
                        <div className="border p-3 rounded-md max-h-[200px] overflow-y-auto">
                            {selectedItem?.tagNames ?
                                (selectedItem?.tagNames?.map((tag: any, index: number) => (
                                    <span key={index} className="d-inline-block mb-2 text-base text-dark text-sm border me-2 rounded-md p-2 bg-whitesmoke " >
                                        {tag.TagName}
                                    </span>
                                )))
                                : 'N/A'}
                        </div>
                    </div>

                    <div className="mb-2">
                        <strong className="fw-bold text-dark text-base text-gray mb-2 d-block">Linked Resources:</strong>
                        <div className="border p-3 rounded-md max-h-[200px] overflow-y-auto">
                            {selectedItem?.LinkedResourceIds ?
                                (selectedItem?.LinkedResourceIds?.map((tag: any, index: number) => (
                                    <span key={index} className="d-inline-block mb-2 text-base text-dark text-sm border me-2 rounded-md p-2 bg-whitesmoke " >
                                        {tag.title}
                                    </span>
                                )))
                                : 'N/A'}
                        </div>
                    </div>


                </div>
            </div>
            {deletePopup && <DeleteConformation successHandle={deleteSuccessHandle} actionType='Delete' title='Delete Conformation' closeModal={setShowDeletePopup} />}

        </div>
    )
}
