import { httpService } from "./api/Api";
import axios from "axios";


// To get moderation list
export const getModerationList = (obj: any) => {
    return httpService.post('Moderation/GetAllModerationList', obj);
}

// To Approve tip 
export const approveOrRejectModeration = (obj: any) => {
    return httpService.post('Moderation/ApproveOrRejectModeration', obj);
}
// to view moderation detials by id 
export const getMdrtnDetailsByID = (id:any) => {
    return httpService.get('Moderation/GetModerationDetailsById'+'/' + id);
}
// TO delete the tip
export const deleteModeration = (obj: any) => {
    return httpService.post('Moderation/DeletePost', obj);
}
// To get flagged list
export const getFlaggedList = (obj: any) => {
    return httpService.post('Moderation/GetAllModerationFlaggedList', obj);
}

// to view flagged detials by id 
export const getFlaggedDetailsByID = (id:any,type:any) => {
    return httpService.get('Moderation/GetMFlaggedDetailsById'+'/' + id + '/' + type);
}
// TO delete the =moderation flagged 
export const deleteFlaggedItem = (obj: any) => {
    return httpService.post('Moderation/DeleteFlagged', obj);
}