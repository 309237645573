import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { RxCross2 } from 'react-icons/rx';
import InputField from '../../components/ui/Input';
import TextareaField from '../../components/ui/TextArea';

// interface Question {
//   id: number;
//   question: any;
//   type: any;
//   options?: { id: number; label: string; value: boolean }[];
//   answer: any; 
// }

interface FeedbackFormProps {
    optedAnsersList: any
    setOpenPopup: any
}

const ViewFeedbackForm: React.FC<FeedbackFormProps> = ({ optedAnsersList, setOpenPopup }) => {
    return (
        <div className="position-fixed  w-100 start-0 top-0 h-100 z-[99999]">
            <div className="d-flex align-items-center h-100 bg-dark justify-content-center bg-opacity-50 p-2">
                <div className=" br-8 bg-white  shadow-xxl border justify-content-center  px-1  w-[750px] overflow-x-auto h-[650px] " >
                    <div className='p-3 '>
                        <div className="">
                            <div className="d-flex justify-content-end">
                                <RxCross2 onClick={() => setOpenPopup(false)} size={25} className="cursor-pointer" />
                            </div>
                            <div className="d-flex justify-content-center">
                                <h4 className="fw-bold mb-2" > FeedBack Template</h4>
                            </div>

                            <div className="overflow-x-auto h-[500px] p-2">
                                <div className="form-group mb-2">
                                    <InputField
                                        name="title"
                                        label="Title"
                                        value={optedAnsersList?.title}
                                        placeholder="Enter Title "
                                        onChange={function (e: React.ChangeEvent<HTMLInputElement>): void {
                                            throw new Error('Function not implemented.');
                                        }} />
                                </div>

                                <div className="form-group mb-2 mt-3">
                                    <TextareaField
                                        name="description"
                                        label="Description"
                                        componentName=""
                                        value={optedAnsersList?.description}
                                        placeholder="Enter Description" onChange={undefined} />
                                </div>

                                {/* Render questions */}

                                {optedAnsersList?.questions.map((question: any) => (
                                    <div key={question.id} className="question-block border p-2 mb-4">
                                        <div>
                                            <label>Question  {question.id}  : </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter your question"
                                                value={question.question}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <div className="position-relative smart-dropdown mb-2">
                                                <label className='me-2'>Answer Type: </label>

                                                <Dropdown
                                                    // options={options}
                                                    // onChange={(e) => handleAnswerTypeChange(questionIndex, e)}
                                                    value={question.type}
                                                    placeholder={question.type}
                                                    disabled
                                                    className='text-dark'
                                                />
                                            </div>
                                        </div>

                                        {/* Render different types of questions */}
                                        {question?.type === 'plainText' && (
                                            <p><strong>Answer:</strong> {question.answer || 'No answer provided'}</p>
                                        )}

                                        {question.type === 'singleSelection' && (
                                            <div>
                                                {question.options?.map((option: any) => (
                                                    <div key={option.id}>
                                                        <input
                                                            type="radio"
                                                            id={`q-${question.id}-opt-${option.id}`}
                                                            name={`question-${question.id}`}
                                                            value={option.label}
                                                            checked={question.answer === option.id}
                                                        // disabled

                                                        />
                                                        <label htmlFor={`q-${question.id}-opt-${option.id}`}>{option.label}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        {question.type === 'multipleSelection' && (
                                            <div>
                                                {question.options?.map((option: any) => (
                                                    <div key={option.id}>
                                                        <input
                                                            type="checkbox"
                                                            id={`q-${question.id}-opt-${option.id}`}
                                                            name={`question-${question.id}`}
                                                            value={option.label}
                                                            checked={question.answer.includes(option.label)}
                                                        // disabled
                                                        />
                                                        <label htmlFor={`q-${question.id}-opt-${option.id}`}>{option.label}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewFeedbackForm;
