import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { BsFilterLeft } from "react-icons/bs";
import UserServeyPopup from "../../components/common/modals/UserServeyPopup";
import { ErrorMessages } from "../../constants/Labels";
import { getTemplatesList } from "../../services/UserManagementService";
import { formatDates } from "../../utils/common/Format";
import { showToastError } from "../../utils/common/ToastMessages";
import OptedUsersList from "./OptedUsersList";
import Pagination from "@mui/material/Pagination";

export default function TemplateList() {
    const [serveyPopup, setShowServeyPopup] = useState(false);
    const [selectedListType, setSelectedListType] = useState('templateList')
    const storedTemplatesString = localStorage.getItem('templateNames');
    const storedTemplates = storedTemplatesString ? JSON.parse(storedTemplatesString) : [];
    const [checkedTemId, setCheckedTempId] = useState(0)
    const [templatesList, setTemplatesList] = useState([])
    const [pageNumber, setPageNumber] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const recordsPerPage = 10; // Number of records per page


    const options = storedTemplates.map((template: any) => ({
        label: template.Title,
        value: template.FeedbackTemplateID
    }));
    const [selectedOption, setSelectedOption] = useState(options.length > 0 ? options[0].value : null);


    const listMethod = (listMethod: any) => {
        setSelectedListType(listMethod)
    }
    const servey = () => {
        setShowServeyPopup(true)
    }

    const handleChange = (event: any) => {
        const selectedTemplateID = event.value;
        setSelectedOption(event.value); // Set the selected value in state
    }
    // Pagination
    const handlePageChange = (_event: any, value: any) => {
        setPageNumber(value);
    };

    //to get user survey templates
    const getTemplatesLists = () => {
        let obj = {
            "feedbackTemplateID": checkedTemId,
            "pageNumber": pageNumber,
            "pageSize": 10
        }
        getTemplatesList(obj).then((response: any) => {
            if (response?.status) {
                setTemplatesList(response?.questionTemplates[0]?.feedbackQuestionTemplateList)
                setTotalCount(response.questionTemplates[0].totalCount);
            } else {
                showToastError(response?.message)
            }
        }).catch((error: any) => {
            showToastError(ErrorMessages.SomethingWrong)
        })
    }
    const handletoggleCheck = (event: any, tempId: any) => {
        setCheckedTempId(tempId)
    }
    useEffect(() => {
        getTemplatesLists()
    }, [checkedTemId, pageNumber])

    const totalPages = Math.ceil(totalCount / recordsPerPage);
    console.log(totalCount, totalPages)


    return (
        <div className="container-fluid px-3">
            <div className="d-flex justify-content-between mt-0 w-100">
                <div className="bg-white rounded shadow-sm w-100 d-flex justify-content-between align-items-center p-3 z-intial h-[68px]">
                    <h3 className="fw-bold mb-0">Survey </h3>

                    <div className="d-flex justify-content-end ">
                        <button className="btn  btn-primary d-flex justify-content-center align-items-center text-white  w-[100px]" onClick={servey} >Survey </button>
                    </div>

                </div>
            </div>
            <div className="d-flex justify-content-between  my-3 bg-white rounded p-2 resourcelist-tab-panel">
                <div className="d-flex justify-content-end mt-0 align-items-center">
                    <button className={` p-3 fs-sm py-2 border text-center me-2 w-[160px] cursor-pointer  rounded ${selectedListType === 'templateList' ? ' bg-secondary text-white shadow-sm ' : '  fw-normal'}`} onClick={() => { listMethod('templateList'); setPageNumber(1); setSelectedOption(options.length > 0 ? options[0].value : null) }} > Template List</button>
                    <button className={` p-3 fs-sm py-2 border text-center me-2 w-[160px] cursor-pointer  rounded ${selectedListType === 'usersList' ? ' bg-secondary text-white shadow-sm ' : '  fw-normal'}`} onClick={() => { listMethod('usersList'); setPageNumber(1) }} >Users List</button>
                </div>
                {selectedListType === 'usersList' &&
                    <div className="d-flex justify-content-end mt-0 align-items-center">
                        <div className="position-relative smart-dropdown">
                            <Dropdown options={options} onChange={handleChange} value={selectedOption} placeholder="Select a template" />
                            <BsFilterLeft className="position-absolute start-0 top-50 translate-y-[-50%] text-gray ms-2" />
                        </div>
                    </div>
                }
            </div>
            <div>
                {selectedListType == 'templateList' &&
                    <><div className="smart-table overflow-x-auto shadow-sm border rounded mxh-calc-321">
                        <table className="table table position-relative my-0">
                            <thead className="th-pattenblue">
                                <tr className="fw-bold sticky top-0 z-[9] bg-white">
                                    <th scope="col"> Title </th>
                                    <th scope="col"> Description </th>
                                    <th scope="col"> Date </th>
                                    <th scope="col"> Action </th>
                                </tr>
                            </thead>
                            <tbody>
                                {templatesList?.length > 0 ?
                                    templatesList?.map((item: any, index: any) => (
                                        <tr key={index} className={`${templatesList?.length == 1 ? 'moreoptions-position-fixed  ' : ''}`} style={{ color: item.IsHide ? 'grey' : '' }}>
                                            <td title={item?.title}>{item?.title?.length > 50 ? item?.title?.slice(0, 50) + '...' : item?.title}</td>
                                            <td title={item?.description}>{item?.description?.length > 50 ? item?.description?.slice(0, 50) + '...' : item?.description}</td>
                                            <td>{formatDates(item.CreatedDate)} </td>
                                            <td className="actions-column d-flex justify-content-start ">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input cursor-pointer" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={(e) => { handletoggleCheck(e, item?.FeedbackTemplateID); }} checked={!item?.IsHide} title={!item?.IsHide ? "Hide Template" : "Show Template"} />
                                                </div>
                                            </td>
                                        </tr>
                                    )) : <tr><td colSpan={12}><h6 className="fw-bold mt-3 d-flex justify-content-center">No data found </h6> </td></tr>}
                            </tbody>
                        </table>
                    </div><div className="d-flex justify-content-end mt-2 ">
                            <div className="bg-white rounded p-2 shadow-sm z-[99] z-intial">
                                <Pagination
                                    count={totalPages}
                                    variant="outlined"
                                    shape="rounded"
                                    page={pageNumber}
                                    onChange={handlePageChange}
                                    siblingCount={1} // Adjust as necessary
                                    boundaryCount={1} // Adjust as necessary
                                    showFirstButton
                                    showLastButton />
                            </div>
                        </div></>
                }
            </div>

            {serveyPopup && <UserServeyPopup setShowServeyPopup={setShowServeyPopup} />}
            {selectedListType == 'usersList' && <OptedUsersList selectedOption={selectedOption} />}
        </div>
    )

}



