import { MultiSelect } from "primereact/multiselect";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { IoArrowBackOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate } from "react-router-dom";
import categories from "../../assets/categories.json";
import imageData from "../../assets/icons.json";
import IconsPopup from "../../components/common/modals/IconsPopupUp";
import InputField from "../../components/ui/Input";
import QuillToolbar, { formats, modules } from "../../components/ui/QuillToolbar";
import { ErrorMessages } from "../../constants/Labels";
import { getLoginUserID } from "../../services/AuthService";
import { deletePostFile, getPostDetailsByID, UpdatePost } from "../../services/CommunityService";
import { getLookUPData } from "../../services/ResourcesService";
import { showToastError, showToastSuccess } from "../../utils/common/ToastMessages";
import { isNotEmptyString, notAllowSpaces } from "../../utils/common/Validation";


export default function EditCommunityPosts() {
    let userDetails = getLoginUserID();
    const navigate = useNavigate()
    const urlPattern = /^(https?:\/\/)?((([a-zA-Z\d]([a-zA-Z\d-]*[a-zA-Z\d])*)\.)+[a-zA-Z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-zA-Z\d%_.~+&=]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(#[-a-zA-Z\d_]*)?$/i; //working uat url resource, community, contribute
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [webLink, setWebLink] = useState("");
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [selectedTagItems, setselectedTagItems] = useState<any>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [newFiles, setNewFiles] = useState<File[]>([]); // Newly added files
    const [postIdsToDelete, setPostsToDelete] = useState<any[]>([]);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [selectedItem, setSelectedItem] = useState<any>()
    const [Topicoptions, setTopicoptions] = useState<any>([]);
    const [Tagptions, setTagptions] = useState<any>([]);
    const [inputValue, setInputValue] = useState("");
    const [inputTagValue, setInputTagValue] = useState("");
    const [isSensitive, setIsSensitive] = useState(false);
    const selectedItemsTooltip = useRef(null);
    const location = useLocation()
    const selectedpostId = location.state
    const [IconFiles, setIconsFiles] = useState<File[]>([]);
    const [selectedIconsImages, setSelectedIconsImages] = useState<any>([])



    // To get the lookup data  
    const lookupdata = () => {
        getLookUPData().then((response: any) => {
            setTagptions(response[0]?.Tags);
            setTopicoptions(response[0]?.Topics);
        })
            .catch(() => {
                showToastError("Error while getting lookup data");
            });
    };
    // To view post details
    const viewTipDetails = async () => {
        try {
            const response: any = await getPostDetailsByID(selectedpostId);
            setSelectedItem(response?.postDetails[0]);
        } catch (error) {
            showToastError(ErrorMessages.SomethingWrong);
        }
    };
    // TO call lookup data & tipdetials on load
    useEffect(() => {
        if (selectedpostId !== undefined) {
            viewTipDetails();
            lookupdata();
        }
    }, [selectedpostId]);

    // In edit mode to bind values to the form
    useEffect(() => {
        if (selectedItem && Object.keys(selectedItem).length > 0) {
            setTitle(selectedItem.title);
            setDescription(selectedItem.description);
            setWebLink(selectedItem.link);

            const selectedTopics = Array.isArray(selectedItem?.topicNames) ? selectedItem?.topicNames : [];
            setSelectedItems(selectedTopics);

            const selectedTags = Array.isArray(selectedItem?.tagNames) ? selectedItem?.tagNames : [];
            setselectedTagItems(selectedTags);

            setIsSensitive(selectedItem?.isSensitive)
            // IMAGES
            if (selectedItem.images) {
                const files = selectedItem.images.map((mediaItem: any) => ({
                    name: mediaItem.FileName,
                    Id: mediaItem.PostMediaID
                }));
                setFiles(files);
                setNewFiles(files);
            } else {
                setFiles([]);
                setNewFiles([]);
            }
            // ICONS
            if (selectedItem && selectedItem.media) {
                const icons = selectedItem?.media.map((mediaItem: any) => ({
                    name: mediaItem.FileName,
                    Id: mediaItem.PostMediaID,
                    Filepath: mediaItem.PostMediaLink,
                    RFilePath: mediaItem.PostMediaLink,
                    IsNew: 0
                }));
                setIconsFiles(icons);
                // setNewIconFiles(icons);
                setSelectedIconsImages(icons)
            } else {
                setIconsFiles([]);
                // setNewIconFiles([]);
                setSelectedIconsImages([])
            }
        }
    }, [selectedItem]);

    // Form handlechange function
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));

        switch (name) {
            case "title":
                setTitle(value);
                break;
            case "webLink":
                setWebLink(value);
                break;
            default:
                break;
        }
    };
    const handleDescriptionChange = (
        value: string,
        delta: any,
        source: any,
        editor: any
    ) => {
        setDescription(value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            description: "",
        }));
    };
    const handleCheckboxChange = () => {
        setIsSensitive(prevIsSensitive => !prevIsSensitive);
    };
    // To remove the uploaded file
    const removeFile = (index: number, postId: any) => {
        if (postId) {
            setPostsToDelete((prevIds: any) => [...prevIds, postId]);
            setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
        } else if (selectedItem === '') {
            setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
        }
        else {
            setFiles(prevFiles => {
                const updatedFiles = prevFiles.filter((_, i) => i !== index);
                return updatedFiles;
            });
        }
    };
    // To remove the uploaded icon
    const removeIcon = (index: number, postId: any) => {
        if (postId) {
            setPostsToDelete((prevIds: any) => [...prevIds, postId]);
            setSelectedIconsImages((prevFiles: any) => prevFiles.filter((_: any, i: number) => i !== index));
        } else if (selectedItem === '') {
            setSelectedIconsImages((prevFiles: any) => prevFiles.filter((_: any, i: number) => i !== index));
        }
        else {
            setSelectedIconsImages((prevFiles: any) => {
                const updatedFiles = prevFiles.filter((_: any, i: number) => i !== index);
                return updatedFiles;
            });
        }
    };
    // convert resources form data into form data payload
    const submitHandler = (e: any) => {
        e.preventDefault();
        const tagNames = selectedTagItems?.map((item: any) => item.TagName).join(",");
        const topicNames = selectedItems?.map((item: any) => item.TopicName).join(",");
        const newErrors: { [key: string]: string } = {};
        if (!isNotEmptyString(title)) {
            newErrors.title = ErrorMessages.TitleRequired;
        }
        if (selectedItems.length === 0) {
            newErrors.topic = ErrorMessages.TopicsRequired;
        }
        if (isNotEmptyString(webLink) && !urlPattern.test(webLink)) {
            newErrors.webLink = "Please enter a valid URL";
        }
        if (files.length > 5) {
            newErrors.files = ErrorMessages.ImagesRequired;
        }
        setErrors(newErrors);
        // If there are no errors, creating payload
        if (Object.keys(newErrors).length === 0) {
            if (postIdsToDelete.length > 0) {
                postIdsToDelete.map((postIds: any) => {
                    let obj = {
                        "postMediaLinkId": postIds
                    };
                    return deletePostFile(obj).then((res: any) => {
                    })
                        .catch(error => {
                            showToastError("Failed to delete file but you can proceed with updating the post.");
                        });
                });
            }
            const formData = new FormData();
            formData.append("Title", title);
            formData.append("Description", description);
            formData.append("Link", webLink);
            formData.append("ModifiedBy", userDetails?.userId);
            formData.append("TagNames", tagNames);
            formData.append("TopicNames", topicNames);
            formData.append("PostId", selectedItem.postId);
            formData.append("IsSensitive", isSensitive.toString());
            // Images
            const uniqueFromSecondArray = files.filter(item => !newFiles.includes(item));
            if (files.length > 0) {
                uniqueFromSecondArray.forEach((file: any) => {
                    formData.append("images", file);
                });
            } else {
                formData.append("images", '');
            }

            const uniqueFromSecondArrayIcons = selectedIconsImages.filter((icon: any) =>
                !IconFiles.some((existingFile: any) => existingFile.Filepath === icon.Filepath)
            );
            // Remove the `RFilePath` and `IsNew` keys from each object
            const cleanedArray = uniqueFromSecondArrayIcons.map((item: any) => {
                const { RFilePath, IsNew, ...rest } = item;
                return rest;
            });
            formData.append("files", JSON.stringify(cleanedArray) ? JSON.stringify(cleanedArray) : ' ');

            UpdatePostDeatils(formData)
        }
    };
    const UpdatePostDeatils = (formData: any) => {
        UpdatePost(formData).then((response: any) => {
            if (response.status) {
                showToastSuccess(response.message);
                navigate('/community')
                return;
            }
            showToastError(response.message);
        })
            .catch(() => {
                showToastError("An error occurred while updating Post");
            });
    };
    // TO avoid empty spaces cursor moving
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        notAllowSpaces(event.currentTarget.value, event);
    };
    // Handlers for Tag and Topic changes
    const handleTagChanges = (e: any) => {
        const selectedTags = e.value.map((tagName: string) =>
            Tagptions?.find((tag: any) => tag?.TagName === tagName)
        );
        setselectedTagItems(selectedTags);
    };
    // Handle change to set selected topics
    const handleTopicChanges = (e: any) => {
        const selectedTopics = e.value.map((topicName: string) =>
            Topicoptions?.find((topic: any) => topic?.TopicName === topicName)
        );
        setSelectedItems(selectedTopics);
    };

    // To show newly added Topic
    const handleAddNewItem = (event: React.KeyboardEvent) => {
        if ((event.key === "Enter" || event.key === "Tab") && isNotEmptyString(inputValue)) {
            event.preventDefault(); // Prevent default behavior
            if (inputValue && !(Topicoptions ?? []).some((option: any) => option?.TopicName === inputValue)) {
                const newTopic = { TopicId: " ", TopicName: inputValue };
                setTopicoptions([...(Topicoptions ?? []), newTopic]);
                setSelectedItems([...selectedItems, newTopic]);
                setInputValue("");
            }
        }
    };
    // To show newly added tag
    const handleAddNewTag = (event: React.KeyboardEvent) => {
        if ((event.key === "Enter" || event.key === "Tab") && isNotEmptyString(inputTagValue)) {
            event.preventDefault(); // Prevent default behavior
            if (
                inputTagValue &&
                !(Tagptions ?? []).some((option: any) => option.TagName === inputTagValue)
            ) {
                const newTag = { TagId: " ", TagName: inputTagValue };
                setTagptions([...(Tagptions ?? []), newTag]);
                setselectedTagItems([...selectedTagItems, newTag]);
                setInputTagValue("");
            }
        }
    };
    // Template to add new topics
    const headerTemplate = () => {
        return (
            <div className="p-multiselect-header">
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleAddNewItem}
                    placeholder="Type and press enter to add new topic"
                    className="p-multiselect-filter form-control"
                />
            </div>
        );
    };
    // Template to add new tags
    const headerTemplateTag = () => {
        return (
            <div className="p-multiselect-header">
                <input
                    type="text"
                    value={inputTagValue}
                    onChange={(e) => setInputTagValue(e.target.value)}
                    onKeyDown={handleAddNewTag}
                    placeholder="Type and press enter to add new tags"
                    className="p-multiselect-filter form-control"
                />
            </div>
        );
    };
    // Files drag and drop
    const onDrop = useCallback((acceptedFiles: File[]) => {
        const maxImages = 5;

        const filteredFiles = acceptedFiles.filter(file => validImageTypes.includes(file.type));
        if (filteredFiles.length !== acceptedFiles.length) {
            showToastError('You can able to upload only images.')
        }
        // setFiles(prevFiles => [...prevFiles, ...filteredFiles]);
        if (filteredFiles.length + files.length > maxImages) {
            showToastError(`Please select a maximum of ${maxImages} images.`);
        } else {
            setFiles(prevFiles => [...prevFiles, ...filteredFiles]);
        }
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*' as any });
    // ICONS UPLOAD CODE
    const [showPopup, setShowPopup] = useState(false)

    const openIconPopup = () => {
        setShowPopup(true)
    }
    const handleImageSelection = (selectedImagesFromPopup: string[]) => {
        setSelectedIconsImages(selectedImagesFromPopup); // Update the state with selected images
        setShowPopup(false);
    };
    const closePopup = () => {
        setShowPopup(false);
    };

    return (
        <div className="px-3">
            <div className="bg-white rounded shadow-sm p-3 mih-calc-85 ">
                <div className="d-flex align-items-center mb-3">
                    <IoArrowBackOutline size={20} className="me-2  cursor-pointer" onClick={() => navigate('/community')} />
                    <h3 className="fw-bold my-0 pt-2">Update Post</h3>
                </div>

                <form onSubmit={submitHandler}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="position-relative mb-3 h-49">
                                <InputField
                                    name="title"
                                    label="Title"
                                    value={title}
                                    placeholder="Enter your Post Title here "
                                    onChange={handleChange}
                                    error={errors.title}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                            <div className="form-group position-relative mb-3 ">
                                <label className="form-group ">Add Topic</label>
                                <div className="smart-multiselect">
                                    <MultiSelect
                                        value={selectedItems?.map((item: any) => item?.TopicName)}
                                        options={Topicoptions?.map((option: any) => ({
                                            label: option?.TopicName,
                                            value: option?.TopicName,
                                        }))}
                                        onChange={handleTopicChanges}
                                        filter
                                        display="chip"
                                        panelHeaderTemplate={headerTemplate}
                                        style={{ width: "100%" }}
                                        placeholder="Select Topics"
                                        ref={selectedItemsTooltip}

                                    />

                                    {errors.topic && (
                                        <div className="error text-danger mt-5">{errors?.topic}</div>
                                    )}
                                </div>
                            </div>
                            <div className="form-group position-relative mb-3">
                                <label className="form-group ">Add Tag</label>
                                <div className="smart-multiselect">
                                    <MultiSelect
                                        value={selectedTagItems?.map((item: any) => item?.TagName)}
                                        options={Tagptions?.map((option: any) => ({
                                            label: option?.TagName,
                                            value: option?.TagName,
                                        }))}
                                        onChange={handleTagChanges}
                                        filter
                                        display="chip"
                                        panelHeaderTemplate={headerTemplateTag}
                                        style={{ width: "100%" }}
                                        placeholder="Select Tags"
                                        ref={selectedItemsTooltip}
                                    />
                                    {errors.tags && (
                                        <div className="error text-danger mt-1">{errors.tags}</div>
                                    )}
                                </div>
                            </div>
                            <div className="position-relative mb-3">
                                <InputField
                                    name="webLink"
                                    label="Add Link"
                                    value={webLink}
                                    placeholder="Web link "
                                    onChange={handleChange}
                                    error={errors.webLink}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="position-relative mb-0 ">
                                <div className="form-group position-relative mb-3 textarea-h-273">
                                    <QuillToolbar />
                                    <div className="" >
                                        <ReactQuill
                                            value={description}
                                            onChange={handleDescriptionChange}
                                            placeholder="Enter your Description here"
                                            modules={modules}
                                            formats={formats}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">

                        </div>
                        <div className="col-md-12">

                        </div>
                        <div className="col-md-12">
                            <div className="form-group position-relative mb-2">
                                <label className="form-group ">Upload Images</label>
                                <div
                                    {...getRootProps()}
                                    style={{
                                        border: "2px dashed #cccccc",
                                        borderRadius: "4px",
                                        padding: "20px",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        marginBottom: "10px",
                                        background: "white",
                                    }}
                                >
                                    {isDragActive ? (
                                        <p className="my-1 text-gray">Drop the Images here ...</p>
                                    ) : (
                                        <p className="my-1 text-gray">Drag & Drop your Images here</p>
                                    )}
                                    <span>Or</span>
                                    <div className="mt-2">
                                        <button className="btn btn-primary text-white" type="button">
                                            Browse Images
                                            <input {...getInputProps()} accept="image/*" type="file" />

                                        </button>
                                    </div>
                                    {files?.length > 5 && (
                                        <div className="text-danger position-absolute start-0 text-xs mt-4">{errors.files}</div>
                                    )}
                                </div>
                                {files && files.length > 0 && <div className="row mt-3">
                                    <div className="col-md-12">
                                        <h4 className="d-flex justify-content-start fw-bold">Uploaded Images </h4>
                                        <div className="border px-1 pt-2 pb-0 rounded-md  mxh-160 overflow-y-auto">
                                            <div className="row mx-0">
                                                {files.length > 0 && files?.map((file: any, index: any) => (
                                                    <div
                                                        key={index}
                                                        className="col-md-3 mb-2 px-1"
                                                    >
                                                        <div className="bg-whitesmoke rounded-md p-2 d-flex align-items-center justify-content-between shadow-md border">
                                                            <span className="text-truncket text-sm fw-bold text-truncate pe-1">{file.name}</span>
                                                            <div className="w-[18px] h-[18px] flex-18  max-w-[18px] bg-secondary bg-opacity-50 rounded-circle d-flex align-items-center justify-content-center">
                                                                <RxCross2
                                                                    className=""
                                                                    size={12}
                                                                    onClick={() => removeFile(index, file.Id)}
                                                                    style={{ cursor: "pointer" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                    </div>
                                </div>}


                            </div>
                        </div>
                        {/* ICONS Upload strat strat*/}
                        <div className="col-md-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="d-flex justify-content-start fw-bold mb-0 mt-2">Select Icons</h4>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-2">

                                    <div className="rounded-md p-3 border-dotted-solidate h-[80px]" onClick={openIconPopup}>
                                        <div className="form-group position-relative mb-0 d-flex justify-content-center h-100 align-items-center">
                                            <button className="btn btn-primary px-4 text-white" type="button" >Select Icons</button>
                                        </div>
                                    </div>
                                </div>

                                {selectedIconsImages && selectedIconsImages.length > 0 &&
                                    <div className="col-md-12">
                                        {selectedIconsImages?.length > 0 && <div className="text-lg d-flex justify-content-start font-semibold align-items-center ">Selected Icons: <span className="font-semibold text-primary ps-1 text-lg d-inline-block">{selectedIconsImages?.length || selectedItem?.media?.length}</span></div>
                                        }
                                        <div className="border px-1 pt-2 pb-0 rounded-md  max-h-[200px] overflow-y-auto">
                                            <div className="row mx-0">
                                                {selectedIconsImages.length > 0 && selectedIconsImages?.map((icon: any, index: any) => (
                                                    <div
                                                        key={index}
                                                        className="col-md-1 mb-2 px-1"
                                                    >
                                                        <div className="rounded-md p-2 d-flex flex-column  shadow-md border">
                                                            <div className="d-flex justify-content-end mb-2">
                                                                <div className="w-[16px] h-[16px] flex-16  max-w-[18px] bg-secondary bg-opacity-25 rounded-circle d-flex align-items-center justify-content-center">
                                                                    <RxCross2
                                                                        className=""
                                                                        size={10}
                                                                        onClick={() => removeIcon(index, icon.Id)}
                                                                        style={{ cursor: "pointer" }}
                                                                    />

                                                                </div>
                                                            </div>
                                                            <img src={icon?.RFilePath} alt="images" className="w-[25px] h-[25px] object-contain d-flex  mx-auto" />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="d-flex align-items-center mt-3">
                            <input
                                type="checkbox"
                                checked={isSensitive}
                                onChange={handleCheckboxChange}
                                className="form-check-input cursor-pointer"
                            />
                            <span className="pt-2 d-flex ps-1"> Is sensitive </span>
                        </div>
                        <div className="d-flex justify-content-end mt-5">
                            <button className="btn btn-primary-outline me-2 px-4" type="button" onClick={() => navigate('/community')}> Cancel  </button>
                            <button className="btn btn-primary px-4 text-white" type="submit">   Submit </button>
                        </div>
                    </div>
                </form>
                {showPopup && <IconsPopup images={imageData} onClose={closePopup} handleSubmit={handleImageSelection} categories={categories} selectedImages={selectedIconsImages}
                />}
            </div>
        </div>

    )

}