import { AiOutlinePieChart } from "react-icons/ai";
import { MdOutlineDashboard } from "react-icons/md";
import { RiTeamLine, RiUserSettingsLine } from "react-icons/ri";
import { NavLink, useNavigate } from "react-router-dom";



export default function SideMenu() {

  return (
    <aside className="side-menu bg-white z-10 p-[12px] w-[210px] vh-calc-110 shadow-sm	 rounded-md position-fixed top-[94px] ml-[12px] ">
      <nav className="pt-[6px]">
        <ul className="menu-list p-0 m-0 h-calc-100vh-minus-152">
          <li className="mb-3">
            <NavLink
              to="/home"
              className={({ isActive }) => (isActive ? "rounded active text-decoration-none d-flex align-items-center p-2" : "rounded-md p-2 text-decoration-none d-flex align-items-center")}
            >
              <div className="w-[25px] h-[25px] flex-25 me-2 d-flex align-items-center">
                <MdOutlineDashboard size={25} className="text-dark" />
              </div>
              <div className="h-[25px] d-flex align-items-center">
                <div className="text-dark d-flex align-items-center leading-4 h-100 pt-1">Dashboard</div>
              </div>
            </NavLink>
          </li>
          <li className="mb-3">
            <NavLink
              to="/resources"
              className={({ isActive }) => (isActive ? "rounded p-2 active text-decoration-none d-flex align-items-center" : "rounded-md p-2 text-decoration-none d-flex align-items-center")}
            >
              <div className="w-[25px] h-[25px] flex-25 me-2 d-flex align-items-center">
                <RiTeamLine size={25} className="text-dark" />
              </div>
              <div className="h-[25px] d-flex align-items-center">
                <div className="text-dark d-flex align-items-center leading-4 h-100 pt-1">Resources</div>
              </div>

            </NavLink>
          </li>
          {/* <li className="mb-3">
            <NavLink
              to="/settings"
              className={({ isActive }) => (isActive ? "active text-decoration-none d-flex align-items-center" : "text-decoration-none d-flex align-items-center")}
            >
              <div className="w-[25px] h-[25px] flex-25 me-1 d-flex align-items-center">
                <IoSettingsOutline size={22} />
              </div>
              <div >Settings</div>
            </NavLink>
          </li> */}
          <li className="mb-3">
            <NavLink
              to="/userManagement"
              className={({ isActive }) => (isActive ? "rounded p-2 active text-decoration-none d-flex align-items-center" : "rounded-md p-2 text-decoration-none d-flex align-items-center")}
            >
              <div className="w-[25px] h-[25px] flex-25 me-2 d-flex align-items-center">
                <RiUserSettingsLine size={22} className="text-dark" />
              </div>
              <div className="h-[25px] d-flex align-items-center">
                <div className="text-dark d-flex align-items-center leading-4 h-100 pt-1"> User Management</div>
              </div>

            </NavLink>
          </li>
          <li className="mb-3">
            <NavLink
              to="/contribute"
              className={({ isActive }) => (isActive ? "rounded p-2 active text-decoration-none d-flex align-items-center" : "rounded-md p-2 text-decoration-none d-flex align-items-center")}
            >
              <div className="w-[25px] h-[25px] flex-25 me-2 d-flex align-items-center">
                <AiOutlinePieChart size={25} className="text-dark" />
              </div>
              <div className="h-[25px] d-flex align-items-center">
                <div className="text-dark d-flex align-items-center leading-4 h-100 pt-2">Tips</div>
              </div>

            </NavLink>
          </li>

          {/* <li className="mb-3">
            <NavLink
              to="/community"
              className={({ isActive }) => (isActive ? "rounded p-2 active text-decoration-none d-flex align-items-center" : "rounded-md p-2 text-decoration-none d-flex align-items-center")}
            >
              <div className="w-[25px] h-[25px] flex-25 me-2 d-flex align-items-center">
                <RiTeamLine size={25} className="text-dark" />
              </div>
              <div className="h-[25px] d-flex align-items-center">
                <div className="text-dark d-flex align-items-center leading-4 h-100 pt-2">Community</div>
              </div>

            </NavLink>
          </li>
           */}
          <li className="mb-3">
            <NavLink to="/moderation" className={({ isActive }) => (isActive ? "rounded p-2 active text-decoration-none d-flex align-items-center" : "rounded-md p-2 text-decoration-none d-flex align-items-center")}
            >
              <div className="w-[25px] h-[25px] flex-25 me-2 d-flex align-items-center">
                <RiTeamLine size={25} className="text-dark" />
              </div>
              <div className="h-[25px] d-flex align-items-center">
                <div className="text-dark d-flex align-items-center leading-4 h-100 pt-2">Moderation</div>
              </div>
            </NavLink>
          </li>
          {/* <li className="mb-3">
            <NavLink to="/survey" className={({ isActive }) => (isActive ? "rounded p-2 active text-decoration-none d-flex align-items-center" : "rounded-md p-2 text-decoration-none d-flex align-items-center")}
            >
              <div className="w-[25px] h-[25px] flex-25 me-2 d-flex align-items-center">
                <RiTeamLine size={25} className="text-dark" />
              </div>
              <div className="h-[25px] d-flex align-items-center">
                <div className="text-dark d-flex align-items-center leading-4 h-100 pt-2">Survey</div>
              </div>
            </NavLink>
          </li> */}
        </ul>
      </nav>
    </aside>
  );
}
