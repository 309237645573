import { useEffect, useState } from "react";
import { ErrorMessages } from "../../constants/Labels";
import { getOptedAnsByUserId, getUserListByTempId } from "../../services/UserManagementService";
import { formatDates } from "../../utils/common/Format";
import { showToastError } from "../../utils/common/ToastMessages";
import ViewFeedbackForm from "./ViewFeedbackForm";
import Pagination from "@mui/material/Pagination";


interface ServeynProps {
    selectedOption?: any
}

const OptedUsersList = ({ selectedOption }: ServeynProps) => {

    const [templatesUsersList, setTemplatesUsersList] = useState([])
    const [optedAnsData, setData] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const recordsPerPage = 10; // Number of records per page

    // Pagination
    const handlePageChange = (_event: any, value: any) => {
        setPageNumber(value);
    };
    const totalPages = Math.ceil(totalCount / recordsPerPage);
    //to get user survey templates
    const getUsersByTempId = () => {
        let obj = {
            "feedbackTemplateID": selectedOption,
            "pageNumber": pageNumber,
            "pageSize": 10
        }
        getUserListByTempId(obj).then((response: any) => {
            if (response?.status) {
                setTemplatesUsersList(response?.questionTemplates[0]?.userFeedbackList)
                setTotalCount(response.questionTemplates[0]?.totalcount);
            } else {
                showToastError(response?.message)
            }

        }).catch((error: any) => {
            showToastError(ErrorMessages.SomethingWrong)
        })
    }
    const [openPopup, setOpenPopup] = useState(false)
    const viewOptedData = (itemId: any, userId: any) => {
        getOptedAnsByUserId(userId, itemId).then((response: any) => {
            if (response.status) {
                setData(response.userFeedback[0]?.AnswerTemplate)
                setOpenPopup(true)
            } else {
                showToastError(response?.message)
            }

        }).catch((error: any) => {
            showToastError(ErrorMessages.SomethingWrong)
        })
    }

    useEffect(() => {
        getUsersByTempId()
    }, [selectedOption, pageNumber])


    return (
        <div>
            <div className="smart-table overflow-x-auto shadow-sm border rounded mxh-calc-321">
                <table className="table table position-relative my-0">
                    <thead className="th-pattenblue">
                        <tr className="fw-bold sticky top-0 z-[9] bg-white">
                            <th scope="col"> User Name </th>
                            <th scope="col"> Email </th>
                            <th scope="col"> Created Date </th>
                        </tr>
                    </thead>
                    <tbody>
                        {templatesUsersList?.length > 0 ?
                            templatesUsersList?.map((item: any, index: any) => (
                                <tr key={index}  >
                                    <td className="cursor-pointer  hover:text-primary" onClick={() => { viewOptedData(item?.userFeedbackID, item?.CreatedBy) }}>{item?.UserName}</td>
                                    <td >{item?.Email}</td>
                                    <td>{formatDates(item.createdDate)} </td>

                                </tr>
                            )) : <tr><td colSpan={12}><h6 className="fw-bold mt-3 d-flex justify-content-center">No data found </h6> </td></tr>}
                    </tbody>
                </table>

            </div>
            <div className="d-flex justify-content-end mt-2 ">
                <div className="bg-white rounded p-2 shadow-sm z-[99] z-intial">
                    <Pagination
                        count={totalPages}
                        variant="outlined"
                        shape="rounded"
                        page={pageNumber}
                        onChange={handlePageChange}
                        siblingCount={1} // Adjust as necessary
                        boundaryCount={1} // Adjust as necessary
                        showFirstButton
                        showLastButton />
                </div>
            </div>
            {openPopup &&
                <ViewFeedbackForm optedAnsersList={optedAnsData} setOpenPopup={setOpenPopup} />
            }
        </div>

    )
}
export default OptedUsersList;