import React, {useEffect, useState } from 'react'
import InputField from '../../ui/Input'
import { isNotEmptyString, isValidEmail, notAllowSpaces } from '../../../utils/common/Validation';
import { ErrorMessages } from '../../../constants/Labels';
import { showToastError, showToastSuccess } from '../../../utils/common/ToastMessages';
import { inviteUser, updateUser } from '../../../services/UserManagementService';
import { setloggedInUserNameUpdate, setUserDetails } from '../../../redux/slice/AuthSlice';
import { useDispatch } from 'react-redux';

interface PopupProps {
    setShowInviteUserPopup?: any
    getUserListData?: any
    selectedUser?: any
}

export default function AddUser({ setShowInviteUserPopup, getUserListData, selectedUser }: PopupProps) {
    const [firstName, setFirstName] = useState("");
    // const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [disableEmail, setEmailDisabled] = useState(false)
    const [selectedUserId , setSelectedUserId] = useState<any>()
    const dispatch = useDispatch();


    useEffect(() => {
        if (selectedUser) {
            setFirstName(selectedUser?.Name)
            setEmail(selectedUser?.Email)
            setSelectedUserId(selectedUser?.UserID)
        }
        if (selectedUser?.Email) {
            setEmailDisabled(true)
        }

    }, [selectedUser])

    // Handle input field changes
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "", }));

        // Update the corresponding state based on the field name
        switch (name) {
            case "firstName":
                setFirstName(value);
                break;
            case "email":
                setEmail(value);
                break;
            default:
                break;
        }
    };
    // To invite user
    const submitHandler = (e: any) => {
        e.preventDefault();
        const newErrors: { [key: string]: string } = {};
        if (!isNotEmptyString(firstName)) {
            newErrors.firstName = ErrorMessages.FirstNameRequired;
        }
        if (!isNotEmptyString(email)) {
            newErrors.email = ErrorMessages.EmailRequired;
        }
        if (!isNotEmptyString(email)) {
            newErrors.email = ErrorMessages.EmailRequired;
        } else if (!isValidEmail(email)) {
            newErrors.email = ErrorMessages.InvalidEmail;
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            if (selectedUser) {
                let obj = {
                    'userId': selectedUser.UserID,
                    'name': firstName
                }
                updateUser(obj).then((response: any) => {
                    if (response.status == true) {
                        setShowInviteUserPopup(false)

                        let userDetails = JSON.parse(localStorage.getItem('UserDetails') || '{}');
                        if (selectedUserId == userDetails?.userId) {
                            userDetails.userName = firstName;
                            const updatedUserDetailsString = JSON.stringify(userDetails);
                            
                dispatch(setUserDetails(updatedUserDetailsString));
                dispatch(setloggedInUserNameUpdate(true)) 
                            localStorage.setItem('UserDetails', updatedUserDetailsString);
                        }
                        showToastSuccess(response.message)
                        getUserListData()
                    } else {
                        showToastError(response.message)
                    }
                }).catch((error: any) => {
                    showToastError(ErrorMessages.SomethingWrong)
                })
            }
            else {
                let obj = {
                    'email': email,
                    'name': firstName
                }
                inviteUser(obj).then((response: any) => {
                    if (response.status == true) {
                        setShowInviteUserPopup(false)
                        showToastSuccess(response.message)
                        getUserListData()
                    } else {
                        showToastError(response?.message)
                        setEmail('')
                    }
                }).catch((error: any) => {
                    showToastError(ErrorMessages.SomethingWrong)
                })
            }

        }
    }
       // TO avoid empty spaces cursor moving
       const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        notAllowSpaces(event.currentTarget.value, event);
    };

    return (
        <div className="position-fixed  w-100 start-0 top-0 h-100 z-[99999]">
            <div className="d-flex align-items-center h-100 bg-dark justify-content-center bg-opacity-50 p-2">
                <div className="rounded-md bg-white  shadow-xxl border justify-content-center w-[400px]">
                    <div className='p-4'>
                        <h4 className='fw-bold mb-4'>Update User</h4>
                        <form onSubmit={submitHandler}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="position-relative mb-1">
                                        <InputField
                                            name="firstName"
                                            label="Name"
                                            value={firstName}
                                            placeholder="Enter Name"
                                            onChange={handleChange}
                                            error={errors.firstName}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="position-relative mb-1 mt-2">
                                        <InputField
                                            name="email"
                                            label="Email"
                                            value={email}
                                            placeholder="Enter Email Address"
                                            onChange={handleChange}
                                            error={errors.email}
                                            disabled={disableEmail}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end mt-32'>
                                    <button
                                        className="btn btn-primary-outline me-2 w-[105px]"
                                        type="button"
                                        onClick={() => setShowInviteUserPopup(false)}>
                                        Cancel
                                    </button>
                                    {selectedUser ? <button
                                        className="btn btn-primary text-white w-[105px]"
                                        type="submit">
                                        Update 
                                    </button> : <button
                                        className="btn btn-primary text-white w-[105px]"
                                        type="submit">
                                        Send Invite
                                    </button>}

                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div></div>
    )


}