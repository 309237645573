import axios from "axios";
import { httpService } from "./api/Api";

// To get community  list
export const getCommunityList = (obj: any) => {
    return httpService.post('Community/GetAllPostList', obj);
}
// get post Details ByID 
export const getPostDetailsByID = (id: any) => {
    return httpService.get('Community/GetPostDetailsById' + '/' + id);
}
// TO delete the tip
export const deletePost = (obj: any) => {
    return httpService.post('Community/DeletePost', obj);
}
// To update the resource
export const UpdatePost = (obj: any) => {
    return httpService.postFile('Community/UpdatePostDetails', obj);
}
// To remove files
export const deletePostFile = (obj: any) => {
    return httpService.post('Community/DeletePostLinkById', obj);
}
// To hide post
export const hidePost = (obj: any) => {
    return httpService.post('Community/HideOrShowPost', obj);
}


// CONTRIBUTE METRICS
// TO get Dashboard Trending topics api 
export const GetTrendingTopics = (obj: any) => {
    return httpService.post('Community/GetTrendingPostsTopics', obj);
}

// TO get Dashboard users api 
export const GetTrendingPosts = (obj: any) => {
    return httpService.post('Community/GetTrendingPosts', obj);
}

export const timelineChart = (obj: any) => {
    return httpService.post('Community/GetTimelineChart', obj);
}
export const getPieChart = () => {
    return httpService.get('Community/GetUserEngagementChart');
}
