import { BiUpArrowAlt } from "react-icons/bi";
import { FaUserLarge } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { formatDateInMonthDateYear } from "../../../utils/common/Format";


interface resourceSubmitProps {
    successHandle?: any
    setShowSubmitPopup?: any
    alreadyExistList?: any
}
export default function ConformResSubmit({ successHandle, setShowSubmitPopup, alreadyExistList }: resourceSubmitProps) {
    return (
        <div className="position-fixed  w-100 start-0 top-0 h-100 z-[99999]">
            <div className="d-flex align-items-center h-100 bg-dark justify-content-center bg-opacity-50 p-2">
                <div className=" br-8 bg-white  shadow-xxl border justify-content-center" style={{ width: '880px' }}>
                    <div className='p-3'>
                        <div className="d-flex align-items-start justify-content-between mb-3">

                            <div>
                                <h4 className="fw-bold d-flex mb-0">Is this  already there ? </h4>
                                <p className="fw-semibold d-flex my-0 text-sm">From the title content this may already be in the system, here's what we've found </p>
                            </div>
                            <div className="d-flex justify-content-end text-gray">
                                <RxCross2 size={20} onClick={() => setShowSubmitPopup(false)} className="cursor-pointer" />
                            </div>
                        </div>
                        <div className=" max-h-[425px] overflow-y-auto mb-3">
                            {alreadyExistList.length > 0 ?
                                alreadyExistList?.map((item: any) => (
                                    <>
                                        <div className="rounded border p-3 mb-1">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <p className="m-0 d-flex justify-content-between"> {item?.uploadedByImage ? <div className="bg-whitesmoke  w-[35px] h-[35px]  rounded-circle d-flex justify-content-center align-items-center"><img src={item?.uploadedByImage} className="img-fluid p-2 h-full w-full rounded-circle !h-auto" /> </div> : <div className="bg-whitesmoke w-[35px] h-[35px] rounded-circle d-flex justify-content-center align-items-center"><FaUserLarge size={20} className="me-0" /></div>}
                                                    <span className="d-flex align-items-center ps-2 font-semibold text-md"> {item?.uploadedBy}</span>
                                                </p>
                                                <p className="text-gray text-md my-0">{formatDateInMonthDateYear(item?.uploadedDateTime)} </p>
                                            </div>
                                            <div className="mt-3">
                                                <label className="d-block mb-0 text-sm text-dark leading-[14px] fw-bold">Title:</label>
                                                <p className="text-gray my-0 text-sm ">{item?.title}</p>
                                            </div>
                                            <div className="mt-3">
                                                <label className="d-block mb-0 text-sm text-dark leading-[14px] fw-bold">Description:</label>
                                                <div className="border rounded-[8px] p-2 max-h-[150px] overflow-y-auto overflo-x-hidden">
                                                <p title={item?.description} className="my-0  text-gray text-sm break-words "> {item?.description?.length > 300 ? item?.description?.slice(0, 300) + '...' : item?.description} </p>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <label className="d-block mb-0 text-sm text-dark leading-[14px] fw-bold">Topics:</label>
                                                <span className="text-sm text-gray d-inline-block">{item?.topicNames?.map((topic: any) => topic.TopicName).join(', ')}</span>
                                            </div>
                                            <div className="mt-3">
                                                <label className="d-block mb-0 text-sm text-dark leading-[14px] fw-bold">Upvotes:</label>
                                                <p className="d-flex me-2 my-0 align-items-center" title="Upvotes">
                                                    <div className="w-[25px] h-[25px] rounded-circle bg-whitesmoke d-flex align-items-center justify-content-center"> <BiUpArrowAlt className="  fw-bold" size={18} /> </div>
                                                     <span className="d-inline-block pt-1 ps-1 text-sm">{item?.upvotesCount}</span>
                                                      </p>
                                            </div>
                                            

                                        </div>

                                    </>

                                )) :
                                <div className="h-100 d-flex align-items-center justify-content-center">
                                    <p className="d-flex justify-content-center font-normal m-0 text-gray"> No matching results found. </p>
                                </div>
                            }



                        </div>
                        <div className="d-flex align-items-center justify-content-between ">
                            <p className="m-0 font-norma text-sm">
                                Are you sure this is different, if so please continue to submit your resource.
                            </p>
                            <div>
                                <button className="btn btn-primary px-4 text-white text-center w-[210px]" type="submit" onClick={successHandle}> Im sure, Submit resource  </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}