import { useEffect, useRef, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { FaUserLarge } from "react-icons/fa6";
import { IoArrowBackOutline } from "react-icons/io5";
import { TiAttachment } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import DeleteConformation from "../../components/common/modals/DeleteConformation";
import { ErrorMessages } from "../../constants/Labels";
import { deleteFlaggedItem } from "../../services/ModerationService";
import { handleClickOutside } from "../../utils/common/CommonUtility";
import { formatDates } from "../../utils/common/Format";
import { showToastError, showToastSuccess } from "../../utils/common/ToastMessages";

interface PopupProps {
    selectedItem?: any;
    setshowDetails?: any;
    setpageNumber?: any
    setFilterValue?: any
    getContributeListData?: any
    tipStatus?: any
    setSearchTerm?: any
    hideHeaderInView?: any

}

export default function FlaggedDetails({ selectedItem, setshowDetails, tipStatus, setpageNumber, setFilterValue, hideHeaderInView, getContributeListData, setSearchTerm }: PopupProps) {
    const [actionIndex, updateActivityIndex] = useState<number | null>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [deletePopup, setShowDeletePopup] = useState(false)
    const [deletingIndex, setDeletingIndex] = useState<any>()

    // Function to clear the activity popup
    const clearActivityPopup = () => {
        updateActivityIndex(null);
    }
    // open actions
    const OpenActionArea = (selectedIndex: number) => {
        updateActivityIndex(selectedIndex);
    };

    useEffect(() => {
        const cleanupClickOutside = handleClickOutside(dropdownRef, clearActivityPopup);
        return () => {
            cleanupClickOutside();
        };
    }, []);

    // To open delete Tip popup
    const [ContentTypes, setConetentType] = useState()
    const deleteItem = (index: any, type: any) => {
        setDeletingIndex(index)
        setShowDeletePopup(true)
        setConetentType(type)
    }
    const deleteSuccessHandle = () => {
        let deleteFlagged: any[] = [];
        deleteFlagged = [{
            contentID: deletingIndex.toString(),
            contentType: ContentTypes
        }];
        const payload = {
            deleteFlagged: deleteFlagged
        };

        deleteFlaggedItem(payload).then((response: any) => {
            if (response.status) {
                showToastSuccess(response.message)
                getContributeListData();
                setShowDeletePopup(false)
                setshowDetails(false)
                hideHeaderInView(true)
            } else {
                showToastSuccess(response.message)
            }

        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong)
            setShowDeletePopup(false)
        })
    }
    return (
        <div>
            <div className='p-4 bg-white border rounded-md mih-calc-85'>
                <div className="  mb-4">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <div className="d-flex align-items-center mb-2">
                            <IoArrowBackOutline
                                size={20}
                                className="me-2  cursor-pointer"
                                onClick={() => {
                                    setshowDetails(false);
                                    hideHeaderInView(true);
                                }}
                            />
                            <h3 className="fw-bold my-0 pt-1">Flagged Details</h3>
                        </div>
                        <div>
                            <div className="d-flex justify-content-between align-items-center" ref={dropdownRef}>
                                <FaEllipsisV className="font-size-10 cursor-pointer text-gray" onClick={() => OpenActionArea(selectedItem)} />
                                <div className={`custom-dropdown-menu position-absolute border mt-[60px] shadow-sm bg-white ml-[-109px] rounded-md w-[120px] ${actionIndex === selectedItem ? '' : 'd-none'}`} >
                                    <div className="dropdown-item cursor-pointer my-0 py-2 leading-4 " onClick={() => deleteItem(selectedItem?.ContentID, selectedItem?.ContentType)}>Delete</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" ms-0">
                        <div className="d-flex justify-content-between align-items-center mb-4 custom-bg-body p-2 rounded-md">
                            <div className="d-flex align-items-center ">
                                <div className="w-[50px] h-[50px] me-3 d-flex justify-content-center align-items-center bg-white p-0 rounded-circle">
                                    {selectedItem?.ProfileImageUrl ? <img src={selectedItem?.ProfileImageUrl} className="img-fluid !h-full w-full object-cover rounded-circle" /> : <div className="bg-white  w-100 h-100 rounded-circle d-flex justify-content-center align-items-center"><FaUserLarge size={20} className="me-0" /></div>}

                                </div>
                                <div className="d-block">
                                    <label className="text-primary mb-0 ">Uploaded by</label>
                                    <h5 className="fw-bold mb-0 ">{selectedItem?.SubmittedBy}</h5>
                                </div>
                            </div>
                            <div className="d-flex align-items-start flex-column">
                                <label className="mb-0 fs text-md text-primary">Uploded Date</label>
                                <h5 className="text-dark fw-bold text-base text-gray mb-0">{formatDates(selectedItem?.CreatedDate)} </h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mb-2">
                                <label className="mb-1 text-base text-dark fw-bold">Title:</label>

                                <h5 className="  font-normal mb-1 text-dark text-base ">{selectedItem?.title}</h5>
                            </div>
                            <div className="col-md-12 mb-3">
                                <div className=" w-100  ">
                                    <div className="">
                                        <div className="d-block text-dark mb-1 text-base fw-bold">Description:</div>
                                        <div className="border p-3 rounded-md">

                                            <div className="max-h-[200px] overflow-y-auto ">
                                                <span
                                                    className="text-base text-dark ul-li-list-normal break-words"
                                                    dangerouslySetInnerHTML={{ __html: selectedItem?.Description }}
                                                ></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <div className="w-100 ">
                                    <div className="w-calc-5px">
                                        <div className="mb-3">
                                            <div className="d-block text-dark mb-0 text-base fw-bold">Topics:</div>
                                            <span className="text-base text-gray">{selectedItem?.topicNames?.map((topic: any) => topic.TopicName).join(', ')}</span>
                                        </div>
                                        <div className="mb-3 d-flex justify-content-start flex-column">
                                            <div className="d-block text-dark mb-0 text-base fw-bold">Sensitive Warning:</div>
                                            <input
                                                type="checkbox"
                                                checked={selectedItem?.IsSensitive}
                                                className="form-check-input"
                                                disabled
                                            />
                                        </div>

                                        <div className="mb-0 d-flex justify-content-between">

                                            <div className="m-0">
                                                <span className="d-block text-dark mb-0 text-base fw-bold">Bookmark Count</span>
                                                <div className="d-flex mx-auto justify-content-center mt-1">
                                                    <div className="text-base text-primary text-center bg-whitesmoke bg-opacity-25 p-2 w-[30px] h-[30px] rounded-circle d-flex align-items-center justify-content-center fw-bold">  {selectedItem?.BookMarkCount} </div>
                                                </div>


                                            </div>
                                            <div className="m-0">
                                                <span className="d-block text-dark mb-0 text-base fw-bold">Up Votes</span>
                                                <div className="d-flex mx-auto justify-content-center mt-1">
                                                    <div className="text-base text-primary text-center bg-whitesmoke bg-opacity-25 p-2 w-[30px] h-[30px] rounded-circle d-flex align-items-center justify-content-center fw-bold">{selectedItem?.upvotesCount}</div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="my-3">
                                    <strong className="fw-bold text-dark text-md fw-bold mb-1 d-block">Links:</strong>
                                    <div className="d-flex justify-content-start bg-whitesmoke p-2 rounded-md ">
                                        {selectedItem?.link ? <> <TiAttachment size={20} className="text-primary me-2" /> <a href={selectedItem?.link} target="_blank" className="cursor-pointer text-dark">{selectedItem.link}</a>
                                        </> : 'N/A'}

                                    </div>
                                </div>
                                <div className="mb-3">
                                    <strong className="fw-bold text-dark text-base text-gray mb-2 d-block">Media:</strong>
                                    <div className="rounded-md p-3 border-dotted-solidate">
                                        <div className="row mx--2 mxh-312 overflow-y-auto">
                                            {selectedItem?.media?.length > 0 && (
                                                selectedItem?.media?.map((item: any, index: number) => (
                                                    <img key={index} src={item?.TipMediaLink} className="col-md-2 px-2 mb-2 h-[100px] object-cover " alt="Tip Image" />
                                                )))}
                                            {selectedItem?.media == null && <span className="">No images found </span>}
                                        </div>

                                    </div>
                                </div>
                                <div className="mb-3">
                                    <strong className="fw-bold text-dark text-base text-gray mb-2 d-block">Icons:</strong>
                                    <div className="rounded-md p-3 border-dotted-solidate">
                                        <div className="row mx--2 mxh-312 overflow-y-auto ">
                                            {selectedItem?.media?.length > 0 && (
                                                selectedItem?.media?.map((item: any, index: number) => (
                                                    <div key={item} className="col-md-1 px-2 mb-2 ">
                                                        <div className="w-100 shadow-md row">
                                                            <span className="media-item  d-flex border rounded-sm w-100 align-items-center justify-content-center p-2 h-[60px] w-[60px] flex-60">
                                                                <img key={index} src={item?.PostMediaLink} className="w-[30px] h-[30px] d-flex mx-auto object-contain" alt="Tip Icon" />
                                                            </span>
                                                        </div>
                                                    </div>

                                                )))}
                                            {selectedItem?.media == null && <span className="">No icons found </span>}

                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <strong className="fw-bold text-dark text-base text-gray mb-2 d-block">Tags:</strong>
                                    <div className="border p-3 rounded-md">
                                        {selectedItem?.TagNames?.length > 0 ? (
                                            selectedItem?.TagNames?.map((tag: any, index: number) => (
                                                <span key={index} className="text-base text-dark text-sm border me-2 rounded-md p-2 bg-whitesmoke " >
                                                    {tag.TagName}
                                                </span>
                                            ))) : "N/A"}

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {deletePopup && <DeleteConformation successHandle={deleteSuccessHandle} actionType='Delete' title='Delete Conformation' closeModal={setShowDeletePopup} />}
                </div>
            </div>
        </div>
    );
}
