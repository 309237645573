import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InputField from '../../components/ui/Input';
import { ErrorMessages } from '../../constants/Labels';
import { ActivateInviteUser, resetPassword } from '../../services/AuthService';
import { showToastError, showToastSuccess } from '../../utils/common/ToastMessages';
import { isNotEmptyString, isPasswordStrong, isStringLengthExceeded } from '../../utils/common/Validation';

import logonew from '../../assets/images/logo.svg';

const ResetPassword: React.FC = () => {
    const [password, setPassword] = useState("");
    const [reEnterPassword, setReEnterPassword] = useState("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [passwordShow, setPasswordShow] = useState(false);
    const [reEnterpasswordShow, setRePasswordShow] = useState(false);
    const navigate = useNavigate()
    const { token } = useParams();
    const location = useLocation();
    const userId = location.state?.userId;

    // Handle input field changes
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "", }));
        switch (name) {
            case "password":
                setPassword(value);
                break;
            case "reEnterPassword":
                setReEnterPassword(value);
                break;
            default:
                break;
        }
    };
    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setPasswordShow(!passwordShow);
    };

    const toggleReEnterPassword = () => {
        setRePasswordShow(!reEnterpasswordShow);
    }
    // To validate and submit passwords
    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const newErrors: { [key: string]: string } = {};
        if (!isNotEmptyString(password)) {
            newErrors.password = ErrorMessages.passwordRequired;
        } else if (!isStringLengthExceeded(password,8)) {
            newErrors.password = ErrorMessages.PasswordStrength6Characters;
        }else if (isStringLengthExceeded(password, 21)) { // Check for maximum length
            newErrors.password = ErrorMessages.PasswordMaxLengthExceeded;  
        }
         else if (!isPasswordStrong(password)) {
            newErrors.password = ErrorMessages.PasswordStrengthPopup;
          }
        else if (!isNotEmptyString(reEnterPassword)) {
            newErrors.reEnterPassword = ErrorMessages.NewPassword;
        } else if (password !== reEnterPassword) {
            newErrors.reEnterPassword = ErrorMessages.PasswordUnmatchError;
        }
        // Update the error state
        setErrors(newErrors);
        if (!token) {
            if (Object.keys(newErrors).length === 0) {
                const object = {
                    userId: userId,
                    newPassword: password,
                };
                resetPassword(object).then((response: any) => {
                    if (response.status == true) {
                        showToastSuccess(response?.message)
                        navigate('/signin')
                        return;
                    }
                    showToastError(response?.message);
                }).catch((error: any) => {
                    showToastError(ErrorMessages.SomethingWrong);
                });
            }
        }
        else {
            if (Object.keys(newErrors).length === 0) {
            let obj = {
                password: password,
                adminToken: token
            }
            ActivateInviteUser(obj).then((response: any) => {
                if (response.status == true) {
                    showToastSuccess(response?.message)
                    navigate('/signin')
                    return
                }
                showToastError(response.message)
            }).catch((error: any) => {
                showToastError(ErrorMessages.SomethingWrong)
            })
        }
    }

    }
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ' ') {
      event.preventDefault();
      showToastError("Spaces are not allowed.")
    } 
  };


    return (
        <div className="container mxw-100">
            <div className="row d-flex align-items-center justify-content-center">
                <div className="col-xl-5 col-lg-6 col-sm-12">
                    <div className="vh-100 d-flex align-items-center justify-content-center w-100">
                        <div className="inner w-100 shadow-sm p-4 border rounded-md bg-white">
                            <img src={logonew} className="d-flex mx-auto w-[120px] mb-[34px]"
                                alt="Autistica" />
                            <div className="d-flex align-items-center justify-content-center mb-0">
                                <h3 className="m-0 fw-bold">Set Password</h3>
                            </div>
                            {token ? <p className="mb-0 text-center">Create a password </p> : <p className="mb-0 text-center">Create a new password. Ensure it differes from privious ones for security </p>}
                            <form onSubmit={submitHandler}>
                                <div className="row mt-4">
                                    <div className="col-md-12 mb-2">
                                        <div className="form-group position-relative mb-2">
                                            <InputField
                                                name="password"
                                                value={password}
                                                label="New Password"
                                                placeholder="Enter New Password"
                                                onChange={handleChange}
                                                error={errors.password}
                                                type={passwordShow ? "text" : "password"}
                                                passwordStrength={8} // Adjust the password strength as needed
                                                onKeyDown={handleKeyDown}
                                            />
                                            {passwordShow ? (
                                                <span className="position-absolute top-50 end-0 mt-1  me-2  cursor-pointer">
                                                    <FaEye
                                                        onClick={togglePasswordVisibility}
                                                    />
                                                </span>
                                            ) : (
                                                <span className="position-absolute top-50 end-0 mt-1 me-2 cursor-pointer">
                                                    <FaEyeSlash

                                                        onClick={togglePasswordVisibility}
                                                    />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group position-relative mb-2">
                                            <InputField
                                                name="reEnterPassword"
                                                value={reEnterPassword}
                                                label="Confirm Password"
                                                placeholder="Enter Confirm Password"
                                                onChange={handleChange}
                                                error={errors.reEnterPassword}
                                                type={reEnterpasswordShow ? "text" : "password"}
                                                onKeyDown={handleKeyDown}
                                            />
                                            {reEnterpasswordShow ? (
                                                <span className="position-absolute top-50 end-0 me-2 mt-1 cursor-pointer">
                                                    <FaEye

                                                        onClick={toggleReEnterPassword}
                                                    />
                                                </span>
                                            ) : (
                                                <span className="position-absolute top-50 end-0 me-2 mt-1 cursor-pointer">
                                                    <FaEyeSlash

                                                        onClick={toggleReEnterPassword}
                                                    />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <button
                                    className="h-[40px] btn btn-primary w-100 d-flex mx-auto justify-content-center m-0 text-white font-size-14 p-y-12 mt-4 mb-2"
                                    type="submit"
                                >
                                    <span className="h-[20px] d-inline-block mt-[2px]">Submit</span>
                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword