import { httpService } from "./api/Api";
import { getAuthTOken } from "./AuthService";
const token = getAuthTOken();

// To Add resource
export const CreateResource = (obj: any) => {
    return httpService.postFile('Resource/ResourceUpload', obj);
}
export const getResources = (obj: any) => {
    return httpService.post('Resource/GetResourceList', obj);
}

export const deleteReource = (obj: any) => {
    return httpService.post('Resource/DeleteResource', obj);
}
export const getLookUPData = () => {
    return httpService.get('Generic/GetLookupData');
}
export const getResourceByID = (id: any) => {
    return httpService.get('Resource/GetResourcesById' + '/' + id);
}
// To update the resource
export const UpdateResourceById = (obj: any) => {
    return httpService.postFile('Resource/UpdateResource', obj);
}
export const deleteReourceFile = (obj: any) => {
    return httpService.post('Resource/DeleteResourceLinkById', obj);
}
// Is resourceAlready exists
export const isExistList = (obj: any) => {
    return httpService.post('Resource/GetAlredyThereResourceList', obj);
}
// To view the resource QI score after submiting
export const QiScoreDetails = (id: any) => {
    return httpService.get('Resource/GetQIScrorsByResourceID/' + id);
}
export const timelineChart = (obj: any) => {
    return httpService.post('Resource/GetTimelineChart', obj);
}
export const getPieChart = () => {
    return httpService.get('Resource/GetUserEngagementChart');
}