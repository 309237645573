import { createSlice, isAction, PayloadAction } from "@reduxjs/toolkit";
import { getLoginUserID } from "../../services/AuthService";

interface StoreBucket {
  userDetails: any;
  showSuccespopup: boolean;
  userName: any
  loggedInUserNameUpdate?: any
  resourceId?: any
}
const loggedInUserName = getLoginUserID()

const initialState: StoreBucket = {
  userDetails: {},
  showSuccespopup: false,
  userName: loggedInUserName?.userName,
  loggedInUserNameUpdate: false,
  resourceId: 0

};

const authSlice = createSlice({
  name: "storeBucket",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setShowAddSuccessPopup: (state, action) => {
      state.showSuccespopup = action.payload;
    },
    setloggedInUserNameUpdate: (state, action) => {
      state.loggedInUserNameUpdate = action.payload;
    },
    setResourceId: (state, action) => {
      state.resourceId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserDetails, setShowAddSuccessPopup, setloggedInUserNameUpdate, setResourceId } = authSlice.actions;

export default authSlice.reducer;
