import moment from "moment";

export const formatDate = (datetimeString: any) => {
    const date = new Date(datetimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const convertDateString = (inputDateString: any) => {
    // Parse the input date string
    const inputDate = new Date(inputDateString);

    // Format the date as "Sun Oct 15 2023 05:30:00 GMT+0530 (India Standard Time)"
    const options: any = {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'long',
    };

    return inputDate.toLocaleString('en-US', options);
};

export const isDateFormat = (input: any) => {
    // Define regular expression patterns for the three date formats
    const datePattern1 = /^\d{2}\/\d{2}\/\d{4}$/;  // dd/mm/yyyy
    const datePattern2 = /^\d{2}-\d{2}-\d{4}$/;     // dd-mm-yyyy
    const datePattern3 = /^\d{4}\/\d{2}\/\d{2}$/;  // yyyy/mm/dd
    const datePattern4 = /^\d{4}-\d{2}-\d{2}$/;     // yyyy-mm-dd

    // Use the test method to check if the input matches any of the patterns
    return datePattern1.test(input) || datePattern2.test(input) || datePattern3.test(input) || datePattern4.test(input);
}

export const setDateFormatDDMMYYY = (inputDate: any) => {
    if (isDateFormat(inputDate)) {
        let date = inputDate.split('/');
        return date[2] + '-' + date[1] + '-' + date[0];
    }
}

export const setDateFormatDDMMYYYPayLoad = (inputDate: any) => {
    if (isDateFormat(inputDate)) {
        let date = inputDate.split('-');
        return date[2] + '/' + date[1] + '/' + date[0];
    }
}

export const setDateFormat = (inputDate: any) => {
    // Parse the input date string into a Date object
    const date: any = new Date(inputDate);

    if (isNaN(date)) {
        // Invalid date input, return an empty string or handle the error as needed
        return '';
    }

    // Get the year, month, and day components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    // Format the date as "YYYY-mm-dd"
    return `${day}/${month}/${year}`;
};

export const convertDateFormat = (inputDate: any) => {
    // Parse the input date string into a Date object
    const dateParts = inputDate.split('/');
    const year = parseInt(dateParts[2], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Month is zero-based (0-11)
    const day = parseInt(dateParts[0], 10);
    const formattedDate = new Date(year, month, day);

    // Format the Date object as a string in day-month-year format
    const options: any = { day: 'numeric', month: 'numeric', year: 'numeric' };
    return formattedDate.toLocaleDateString('en-US', options);
};

export const formatReminderDate = (reminderDate: any) => {
    const today: any = new Date();
    const reminder: any = new Date(reminderDate);

    // Calculate the time difference in milliseconds
    const timeDifference = reminder - today;
    const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds

    if (timeDifference < 0) {
        // If the reminder date is in the past, return the exact date
        return formatDate(reminderDate);
    } else if (timeDifference < oneDay) {
        return 'Today';
    } else if (timeDifference < 2 * oneDay) {
        return 'Tomorrow';
    } else if (timeDifference < 3 * oneDay) {
        return 'Day after tomorrow';
    } else {
        return convertDateFormat(reminderDate);
    }
};

export const fetchTodayDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
}

export const fetchTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 2);
    return tomorrow.toISOString().split('T')[0];
}

export const fetchThisWeekDates = () => {
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - (today.getDay() - 1)); // Set to Monday of the current week
    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + (7 - today.getDay())); // Set to Sunday of the current week

    const dates = [];
    const currentDate = new Date(startOfWeek);

    while (currentDate <= endOfWeek) {
        dates.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1); // Increment by one day
    }

    return dates;
}

export const fetchThisMonthDates = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();

    const startDate = new Date(year, month, 1); // Set to the first day of the current month
    const endDate = new Date(year, month + 1, 0); // Set to the last day of the current month

    const dates = [];
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dates.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1); // Increment by one day
    }

    return dates;
}

export const formatDateDayMonth = (dateString: any) => {
    const date = new Date(dateString);
    const options: any = { day: 'numeric', month: 'short' };
    return date.toLocaleDateString('en-US', options);
};

export const convertTo24HourFormat = (timeStr: string): string => {
    // Convert the time string to lowercase to handle both "AM" and "am"
    timeStr = timeStr.toLowerCase();

    // Check if "am" or "pm" is present in the input
    if (timeStr.includes('am') || timeStr.includes('pm')) {
        // Split the input time string into hours, minutes, and am/pm parts
        const [originalTime, period] = timeStr.split(' ');

        // Split the hours and minutes and convert them to integers
        const [originalHours, minutes] = originalTime.split(':').map((part: string) => parseInt(part, 10));

        let hours: any = originalHours;

        // Convert hours to 24-hour format
        if (period === 'pm' && hours < 12) {
            hours += 12;
        } else if (period === 'am' && hours === 12) {
            hours = 0;
        }

        // Convert hours and minutes back to strings and format them
        const formattedHours = '';
        const formattedMinutes = '';

        // Create the 24-hour formatted time string
        return `${formattedHours}:${formattedMinutes}`;
    } else {
        // No "am" or "pm" found, return the original time string
        return timeStr;
    }
}

export const platformDateFormat = (dateString: any) => {
    if (isDateFormat(dateString)) {
        let date = dateString.split('-');
        return date[2] + '/' + date[1] + '/' + date[0];
    }
}

export const convertDate = (date: any) => {
    // Check if the date format is "DD-MM-YYYY" or "MM-DD-YYYY"
    const isDateFormat1 = /\d{2}-\d{2}-\d{4}/.test(date);
    const isDateFormat2 = /\d{2}\/\d{2}\/\d{4}/.test(date);

    // If it's in "DD-MM-YYYY" format, convert to "MM/DD/YYYY"
    if (isDateFormat1) {
        const [day, month, year] = date.split('-');
        return `${day}/${month}/${year}`;
    }

    // If it's in "MM/DD/YYYY" format, no need to convert
    if (isDateFormat2) {
        return date;
    }

    return date;
};
//remainders DD-MM Method
export const convertUtcToLocalDDMM = (utcTime: any) => {
    // return moment.utc(utcTime).local().format('DD/MM/YYYY HH:mm');
    return moment.utc(utcTime).local().format('DD/MM/YYYY');

};

export const formatTimestamp = (apiTimestamp: any) => {
    // Parse the API timestamp
    const date = new Date(apiTimestamp);

    // Define months array for formatting
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Get the components of the date
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Construct the formatted timestamp string
    const formattedTimestamp = `${formattedHours}:${formattedMinutes} ${ampm} ${day} ${month} ${year}`;

    return formattedTimestamp;
}

//Ex:April 2, 2024
export function formatDateInMonthDateYear(dateString: any) {
    const date = new Date(dateString);
    const options: any = {  day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}
// EX: friday 05 june
export function formatDateInDayDateMonth(dateString:any) {
    const date = new Date(dateString);
    const options:any = { weekday: 'long', day: '2-digit', month: 'long' };
    return date.toLocaleDateString('en-US', options);
}
// ex : 12 sep 2023
export function formatDates(value:any) {
    let date = new Date(value);
    const day = date.toLocaleString('default', { day: '2-digit' });
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.toLocaleString('default', { year: 'numeric' });
    return day + ' ' + month + ' ' + year;
}
