import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { QiScoreDetails } from "../../../services/ResourcesService";

interface ConfirmationProp {
    title: string,
    closeModal: any,
    successHandle?: any,

}

const ResourceAddSuccess = ({ title, closeModal, }: ConfirmationProp) => {
    const [qiScoresList, setQiScoresList] = useState<any>([])
    const resourceId = useSelector((state: any) => state?.userBucket?.resourceId)


    const QiScores = async () => {
        try {
            const response: any = await QiScoreDetails(resourceId);
            if (response?.status) {
                setQiScoresList(response?.qiScores[0]?.qiScore);
            }
        } catch (error) {
            console.error('Error fetching QiScores:', error);
        }
    };
    useEffect(() => {
        QiScores()
    }, [])

    return (
        <div className="position-fixed  w-100 start-0 top-0 h-100 z-[99999]">
            <div className="d-flex align-items-center h-100 bg-dark justify-content-center bg-opacity-50 p-2">
                <div className=" br-8 bg-white shadow-xxl border justify-content-center w-50">
                    <div className='p-4'>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4 className=' mb-0 text-start fw-bold'>{title}</h4>
                            <RxCross2 onClick={closeModal} size={20} className="cursor-pointer text-gray" />
                        </div>
                        {/* <h6 className='font-size-16 text-gray mb-3 text-start d-flex justify-content-between'>Overall QI index score is  <span className=" rounded-pill "></span></h6> */}
                        <div className="p-3 border  max-h-[425px] overflow-y-auto rounded-[8px]" >
                            {qiScoresList?.map((item: any) => (
                                <><div className="d-flex justify-content-between mt-3">
                                    <span className="fw-bold"> {item?.IndexName}</span>
                                    <span className="text-base border text-primary text-center bg-whitesmoke bg-opacity-25 p-2 w-[25px] h-[25px] rounded-circle d-flex align-items-center justify-content-center "> {item?.Score}</span>
                                </div><p className="mt-2">{item?.Description}</p></>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResourceAddSuccess;