import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import InputField from "../../components/ui/Input";
import { ErrorMessages } from "../../constants/Labels";
import { setloggedInUserNameUpdate } from "../../redux/slice/AuthSlice";
import { getLoginUserID, UpdateUserProfile } from "../../services/AuthService";
import { showToastError, showToastSuccess } from "../../utils/common/ToastMessages";
import { isNotEmptyString, notAllowSpaces } from "../../utils/common/Validation";

interface PopupProps {
    setShowUPdateProfile?: any;
}

export default function UpdateProfile({
    setShowUPdateProfile,

}: PopupProps) {
    const [profileImage, setProfileImage] = useState<any>(null);
    const [userProfile, setUserProfile] = useState<any>("");
    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [isEmailDisabled, setIsEmailDisabled] = useState<boolean>(true);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const loggedInUserName = getLoginUserID()

    useEffect(() => {
        setFirstName(loggedInUserName?.userName)
        setEmail(loggedInUserName?.email)
    }, [])

    const uploadImage = (e: any) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setUserProfile(selectedFile);
            const imageURL = URL.createObjectURL(selectedFile);
            setProfileImage(imageURL);
        }
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
        switch (name) {
            case "firstName":
                setFirstName(value);
                break;
            case "email":
                setEmail(value);
                break;

        }
    }
    const dispatch = useDispatch();

    const submitProfileHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const newErrors: { [key: string]: string } = {};
        if (!isNotEmptyString(firstName)) {
            newErrors.firstName = ErrorMessages.FirstNameRequired;
        }

        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            const object = {
                name: firstName,
                userId: loggedInUserName.userId,
            };

            UpdateUserProfile(object).then((response: any) => {
                if (response.status == true) {
                    showToastSuccess(response.message);

                    const userDetailsString = localStorage.getItem('UserDetails');
                    if (userDetailsString) {
                        const userDetails = JSON.parse(userDetailsString);
                        userDetails.userName = firstName;
                        const updatedUserDetailsString = JSON.stringify(userDetails);
                        localStorage.setItem('UserDetails', updatedUserDetailsString);
                        setShowUPdateProfile(false)
                        dispatch(setloggedInUserNameUpdate(true))
                    }
                } else {
                    showToastError(response.message);
                }
            }).catch((error: any) => {
                showToastError(ErrorMessages.SomethingWrong);
            });
        }
    };
    // TO avoid empty spaces cursor moving
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        notAllowSpaces(event.currentTarget.value, event);
    };

    return (
        <div className="position-fixed  w-100 start-0 top-0 h-100 z-[99999]  ">
            <div className="d-flex align-items-center h-100 bg-dark justify-content-center bg-opacity-50 p-2">
                <div className="rounded-md bg-white  shadow-xxl border justify-content-center w-[400px]">
                    <div className='p-4'>
                        <h4 className="text-dark mb-4 d-sm-mb-2 d-xs-text-center fw-bold">
                            Update Profile
                        </h4>
                        <form onSubmit={submitProfileHandler}>
                            <div className=" d-flex justify-content-between flex-column ">
                                <div className="col">
                                    <div className="col-md-12">
                                        <InputField
                                            name="firstName"
                                            label="First Name"
                                            value={firstName}
                                            placeholder="Enter First Name"
                                            onChange={handleChange}
                                            error={errors.firstName}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <InputField
                                            name="email"
                                            label="Email"
                                            value={email}
                                            placeholder="Enter Email"
                                            onChange={handleChange}
                                            error={errors.email}
                                            disabled={isEmailDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end mt-[34px]">
                                    <button
                                        className="btn btn-primary-outline me-1 w-[116px]"
                                        type="button" onClick={() => setShowUPdateProfile(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-primary  text-white w-[116px]"
                                        type="submit"
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}