import { useState } from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { FiPlusCircle } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { ErrorMessages } from "../../../constants/Labels";
import { getLoginUserID } from "../../../services/AuthService";
import { submitSurveyForm } from "../../../services/UserManagementService";
import { formatDates } from "../../../utils/common/Format";
import { showToastError, showToastSuccess } from "../../../utils/common/ToastMessages";
import { isNotEmptyString, notAllowSpaces } from "../../../utils/common/Validation";
import InputField from "../../ui/Input";
import TextareaField from "../../ui/TextArea";

interface ServeynProps {
    setShowServeyPopup?: any
}
interface Question {
    question: string;
    answerType: string;
    numOptions: number;
    options: string[];
}

const UserServeyPopup = ({ setShowServeyPopup }: ServeynProps) => {
    let userDetails = getLoginUserID();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [showQuestionForm, setShowQuestionForm] = useState(false);
    const [questions, setQuestions] = useState<Question[]>([]);
    // const [isQuestionValid, setIsQuestionValid] = useState(true);



    const validateQuestions = () => {
        let valid = true;

        questions.forEach((question) => {
            if (!question.question.trim() || !question.answerType) {
                valid = false;
            }
            if (question.answerType === 'single' || question.answerType === 'multiple') {
                if (question.options.length === 0 || question.options.some(option => option.trim() === '')) {
                    valid = false;
                }
            }
        });
        return valid;
        // Update the state variable based on validation result

    };

    const submitHandler = (e: any) => {
        e.preventDefault();
        const newErrors: { [key: string]: string } = {};

        // Validate title and description
        if (!isNotEmptyString(title)) {
            newErrors.title = "Title is required";
        }
        if (!isNotEmptyString(description)) {
            newErrors.description = "Description is required";
        }

        // Check if no questions are added
        if (questions.length === 0) {
            newErrors.oneQuestions = "Please add at least one question";
        }

        // If questions are invalid, show error
        if (questions.length > 0 && !validateQuestions()) {
            newErrors.oneQuestion = "Please fill all the details in the questions.";
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            return;
        }

        const formattedQuestions = questions.map((question, index: number) => {
            const { answerType, options, question: questionText } = question;

            let formattedQuestion: any = {
                id: index + 1, // Always set to 0 as per your requirement
                question: questionText,
                type: answerType === 'text' ? 'plainText' : answerType === 'single' ? 'singleSelection' : 'multipleSelection',
                answer: answerType === 'multiple' ? [] : null
            };

            // Include options only for single/multiple selection questions
            if (answerType === 'single' || answerType === 'multiple') {
                formattedQuestion.options = options;
            }

            return formattedQuestion;
        });

        const payload = {
            title,
            description,
            questionTemplate: JSON.stringify(formattedQuestions),
            templatePath: '',
            createdBy: userDetails?.userId
        };

        submitSurveyForm(payload).then((response: any) => {
            if (response.status === true) {
                showToastSuccess(response.message);
                setShowServeyPopup(false);
            } else {
                showToastError(response?.message);
            }
        })
            .catch(() => {
                showToastError(ErrorMessages.SomethingWrong);
            });
    };

    // Form handlechange
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));

        switch (name) {
            case "title":
                setTitle(value);
                break;
            case "description":
                setDescription(value);
                break;
            default:
                break;
        }
    };
    const options = [
        { value: 'text', label: 'Plain Text' },
        { value: 'single', label: 'Single Selection' },
        { value: 'multiple', label: 'Multiple Selection' },
    ];

    // Function to handle question input changes
    const handleQuestionChange = (index: any, event: any) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].question = event.target.value;
        setQuestions(updatedQuestions);
    };

    // TO avoid empty spaces cursor moving
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        notAllowSpaces(event.currentTarget.value, event);
    };

    const addQuestion = () => {
        setShowQuestionForm(true)
        setQuestions([...questions, { question: '', answerType: '', numOptions: 2, options: ['', ''] }]);
    };

    // Handle dropdown change
    const handleAnswerTypeChange = (index: any, event: any) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].answerType = event?.value;
        setQuestions(updatedQuestions);
    };

    // Handle increasing the boxes
    const increaseBoxes = (index: any) => {
        if (questions[index].numOptions >= 4) {
            showToastError('You can add maximum of four options only ')
        } else {
            const updatedQuestions = [...questions];
            updatedQuestions[index].numOptions += 1;
            updatedQuestions[index].options.push('');
            setQuestions(updatedQuestions);
        }
        // Add an empty option input

    };

    // Handle decreasing the  boxes (min 2)
    const decreaseBoxes = (index: any) => {
        if (questions[index].numOptions > 2) {
            const updatedQuestions = [...questions];
            updatedQuestions[index].numOptions -= 1;
            updatedQuestions[index].options.pop(); // Remove the last option input
            setQuestions(updatedQuestions);
        }
    };
    // Function to handle option input changes
    const handleOptionChange = (questionIndex: any, optionIndex: any, event: any) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].options[optionIndex] = event.target.value;
        setQuestions(updatedQuestions);
    };

    const removeQuestion = (index: number) => {
        setQuestions((prevQuestions) =>
            prevQuestions.filter((_, i) => i !== index) // Remove question by index
        );
    };
    return (
        <div className="position-fixed  w-100 start-0 top-0 h-100 z-[99999]">
            <div className="d-flex align-items-center h-100 bg-dark justify-content-center bg-opacity-50 p-2">
                <div className=" br-8 bg-white  shadow-xxl border justify-content-center     px-1  w-[750px]">
                    <div className='p-3'>
                        <div className="d-flex justify-content-between">
                            <h6 className="fw-bold" >{formatDates(new Date())}</h6>
                            <RxCross2 onClick={() => setShowServeyPopup(false)} size={20} className="cursor-pointer" />
                        </div>
                        <div className="d-flex justify-content-center">
                            <h4 className="fw-bold mb-2" > FeedBack Template</h4>
                        </div>
                        <form onSubmit={submitHandler}>
                            <div className="overflow-x-auto h-[500px] p-2">
                                <div className="form-group mb-2">
                                    <InputField
                                        name="title"
                                        label="Title"
                                        value={title}
                                        placeholder="Enter Title "
                                        onChange={handleChange}
                                        error={errors.title}
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                                <div className="form-group mb-2 mt-3">
                                    <TextareaField
                                        name="description"
                                        label="Description"
                                        componentName=""
                                        value={description}
                                        onChange={handleChange}
                                        placeholder="Enter Description"
                                        error={errors.description}
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>


                                {showQuestionForm &&
                                    (questions.map((question, questionIndex) => (
                                        <div key={questionIndex} className="">
                                            {/* Question Label */}

                                            <div className="d-flex justify-content-end ">
                                                <RxCross2 size={20} onClick={() => removeQuestion(questionIndex)} className="cursor-pointer" />
                                            </div>
                                            <div>
                                                <label htmlFor={`question-${questionIndex}`}>Question:</label>
                                                <input
                                                    type="text"
                                                    id={`question-${questionIndex}`}
                                                    className="form-control"
                                                    placeholder="Enter your question"
                                                    value={question.question}
                                                    onChange={(e) => handleQuestionChange(questionIndex, e)}
                                                />
                                            </div>

                                            {/* Answer Type Dropdown */}
                                            <div className="mt-3">
                                                <label htmlFor={`answerType-${questionIndex}`}>Answer Type:</label>
                                                <Dropdown
                                                    options={options}
                                                    onChange={(e) => handleAnswerTypeChange(questionIndex, e)}
                                                    value={question.answerType}
                                                    placeholder="Select answer type"
                                                />
                                            </div>

                                            {/* Show Options Boxes if "Multiple Selection" or "Single Selection" is chosen */}
                                            {(question?.answerType === 'multiple' || question?.answerType === 'single') && (
                                                <div className="mt-3">
                                                    {/* Up and Down Arrows to control the number of options */}
                                                    <div className="d-flex align-items-center mb-3">
                                                        <label>Number of Options: {question.numOptions}</label>
                                                        <span className="btn-secondary ms-2">
                                                            <button className="btn" type="button" onClick={() => increaseBoxes(questionIndex)}>↑</button>
                                                            <button className="btn" type="button" onClick={() => decreaseBoxes(questionIndex)}>↓</button>
                                                        </span>
                                                    </div>

                                                    {/* Render input boxes based on the number of options */}
                                                    {question.options.map((option, optionIndex) => (
                                                        <div key={optionIndex} className="mb-2">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={`Option ${optionIndex + 1}`}
                                                                value={option}
                                                                onChange={(e) => handleOptionChange(questionIndex, optionIndex, e)}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    )))}
                                <div className="form-group position-relative mt-2">
                                    <div className="d-flex justify-content-end mb-2">
                                        <button className="btn btn-primary text-white fs-sm align-items-center d-flex justify-content-between text-dark  py-2" type="button" onClick={addQuestion}><FiPlusCircle className="me-1" size={18} />
                                            <div className="h-[15px] text-sm mb-2">Add Question</div>
                                        </button>
                                    </div>
                                    {errors.oneQuestions && (
                                        <div className="error text-danger  ps-0  ">{errors.oneQuestions}</div>
                                    )}
                                    {errors.oneQuestion && (
                                        <div className="error text-danger  ps-0  ">{errors.oneQuestion}</div>
                                    )}
                                </div>

                                <div className='d-flex justify-content-end align-items-end mt-3 pt-2'>
                                    <button className='btn btn-primary-outline me-2 px-4' onClick={() => setShowServeyPopup(false)}>Cancel</button>
                                    <button className='btn btn-primary text-white fs-sm align-items-center' type="submit">Send to users</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserServeyPopup;