import React from "react";
import { FaCheckCircle, FaCircle } from "react-icons/fa"; // Importing icons from react-icons library
import { IoMdArrowDropleft } from "react-icons/io";

interface PasswordStrengthPopupProps {
    password?: string; // Make the password prop optional
    strength: number;
}

function PasswordStrengthPopup({ password, strength }: PasswordStrengthPopupProps) {
    return (
        <>
            {password && (
                <div className="password-Hints end-0 top-7 shadow-md position-absolute bg-white br-4 border p-2 mt-[-10px] me-[-228px]"  style={{}}>
                    <IoMdArrowDropleft size={24} className="text-dark position-absolute top-0 start-0 mt-[12px] ml-[-16px]" />
                    <p className="d-flex align-items-center mb-1">
                        {/(?=.*[A-Z])/.test(password) ? (
                            <FaCheckCircle size={14} className="me-2" />
                        ) : (
                            <FaCircle size={14} className="me-2" />
                        )}
                        <div className="d-flex pt-1">Minimum 1 uppercase</div>
                    </p>
                    <p className="d-flex align-items-center mb-1">
                        {/(?=.*[a-z])/.test(password) ? (
                            <FaCheckCircle size={14} className="me-2" />
                        ) : (
                            <FaCircle size={14} className="me-2" />
                        )}
                        <div className="d-flex pt-1">Minimum 1 lowercase</div>
                    </p>
                    <p className="d-flex align-items-center mb-1">
                        {/(?=.*\d)/.test(password) ? (
                            <FaCheckCircle size={14}  className="me-2" />
                        ) : (
                            <FaCircle size={14}  className="me-2" />
                        )}
                        <div className="d-flex pt-1">Minimum 1 number</div>
                    </p>
                    <p className="d-flex align-items-center mb-1">
                        {/(?=.*[!@#$%^&*()])/.test(password) ? (
                            <FaCheckCircle size={14}  className="me-2" />
                        ) : (
                            <FaCircle size={14}  className="me-2" />
                        )}
                        <div className="d-flex pt-1">Minimum 1 special character</div>
                    </p>
                    <p className="d-flex align-items-center mb-0">
                        {password.length >= strength ? (
                            <FaCheckCircle size={14} className="me-2" />
                        ) : (
                            <FaCircle size={14} className="me-2" />
                        )}
                       <div className="d-flex pt-1">Minimum {strength} characters</div> 
                    </p>
                </div>
            )}
        </>
    );
}

export default PasswordStrengthPopup;
