import { Pagination } from "@mui/material";
import { Dropdown } from "primereact/dropdown";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BsFilterLeft } from "react-icons/bs";
import { FaEllipsisV } from "react-icons/fa";
import { PiArrowsDownUpFill } from "react-icons/pi";
import { RiDeleteBinLine } from "react-icons/ri";
import ApproveTipPopup from "../../components/common/modals/ApprovePopup";
import DeleteConformation from "../../components/common/modals/DeleteConformation";
import { ErrorMessages } from "../../constants/Labels";
import { deleteModeration, getMdrtnDetailsByID, getModerationList } from "../../services/ModerationService";
import { formatDates } from "../../utils/common/Format";
import { showToastError, showToastSuccess } from "../../utils/common/ToastMessages";
import FlaggedList from "./FlaggedList";
import ModerationDetials from "./ModerationDetails";

export default function ModerationList() {
    const [selectedListType, setSelectedListType] = useState('moderation')
    const [pageNumber, setPageNumber] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const recordsPerPage = 10; // Number of records per page
    const [selectedOption, setSelectedOption] = useState<any>('');
    const [searchTerm, setSearchTerm] = useState<any>('')
    const [sortValue, setSortValue] = useState('')
    const [actionIndex, setActionIndex] = useState<number | null>(null);
    const dropdownRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [orderBy, setOrderBy] = useState<any>('Asc');
    const [moderationData, setModerationData] = useState([]);
    const [orderBytopicNames, setOrderBytopicNames] = useState('Asc')
    const [orderBytitle, setOrderBytitle] = useState('Asc')
    const [orderByCreatedDate, setorderByCreatedDate] = useState('Asc')
    const [orderByReason, setOrderByReason] = useState('Asc')
    const [showDetails, setShowDetials] = useState(false)
    const [selectedItem, setSelectedItem] = useState()
    const [deleteItem, setDeleteItem] = useState<any>()
    const [deletePopup, setShowDeletePopup] = useState(false)
    const [selectedApproveId, setSelectedApprovId] = useState<any>()
    const [tipStatus, settipStatus] = useState()
    const [showApprovePopup, setshowApprovePopup] = useState(false)
    const [hideHeader, setHIdeHeader] = useState(true)
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [selectAll, setSelectAll] = useState(false);


    // To clear activity popup
    const clearActivityPopup = useCallback(() => {
        setActionIndex(null);
    }, []);
    // handle clikoutside action popup
    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (dropdownRefs.current.some((ref: any) => ref && ref.contains(event.target as Node))) {
            return;
        }
        clearActivityPopup();
    }, [clearActivityPopup]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);
    // To open actions 
    const OpenActionArea = (index: number) => {
        setActionIndex(index);
    };

    // Dropdown values
    const options = [
        { id: 0, value: 'All', label: "All" },
        { id: 1, value: 'Pending', label: "Pending" },
        { id: 2, value: 'Approved', label: "Approved" },
        { id: 3, value: 'Denied', label: "Rejected" }
    ];
    // To set tab options
    const listMethod = (listMethod: any) => {
        setSelectedListType(listMethod)
    }

    const handleChange = (event: any) => {
        const value = event.target.value;
        setPageNumber(1);
        setSelectedOption(value);
    }
    // TO get the list data
    const getModerationListData = () => {
        let obj = {
            "pageNumber": pageNumber,
            "pageSize": 10,
            'filterBy': selectedOption,
            "startDate": '',
            "endDate": '',
            "sortColumnName": sortValue,
            "sortColumnDirection": orderBy,
            "utcOffset": '',

        }
        getModerationList(obj).then((response: any) => {
            if (response?.status == true) {
                setModerationData(response.moderationList[0]?.ModerationList)
                setTotalCount(response.moderationList[0].TotalCount);
            } else {
                showToastError(response?.message)
            }

        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong)
        })
    }
    useEffect(() => {
        getModerationListData();
    }, [pageNumber, orderBy, selectedOption, searchTerm]);

    //set sorting Order of all table values  
    const toggleOrder = (type: 'topicNames' | 'title' | 'reason' | 'CreatedDate') => {
        if (type === 'reason') {
            const newOrder = orderByReason === 'Asc' ? 'Desc' : 'Asc';
            setOrderByReason(newOrder);
            setSortValue('upvotesCount')
            setOrderBy(newOrder)
        }
        else if (type === 'topicNames') {
            const newOrder = orderBytopicNames === 'Asc' ? 'Desc' : 'Asc';
            setOrderBytopicNames(newOrder);
            setSortValue('topicNames')
            setOrderBy(newOrder)
        }
        else if (type === 'title') {
            const newOrder = orderBytitle === 'Asc' ? 'Desc' : 'Asc';
            setOrderBytitle(newOrder);
            setSortValue('title')
            setOrderBy(newOrder)
        }
        else if (type === 'CreatedDate') {
            const newOrder = orderByCreatedDate === 'Asc' ? 'Desc' : 'Asc';
            setorderByCreatedDate(newOrder);
            setSortValue('CreatedDate')
            setOrderBy(newOrder)
        }
    };
    // Pagination
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageNumber(value);
    };
    const totalPages = Math.ceil(totalCount / recordsPerPage);

    const showItemDetails = (itemId: any, Status: any) => {
        clearActivityPopup()
        settipStatus(Status)
        getMdrtnDetailsByID(itemId).then((response: any) => {
            if (response.status) {
                setSelectedItem(response?.moderationDetails[0])
                setShowDetials(true)
            } else {
                showToastError(response?.message)
            }

        }).catch((error: any) => {
            showToastError(ErrorMessages.SomethingWrong)
        })

    }
    // To approve the tip
    const approveTip = (tipId: any, status: any) => {
        clearActivityPopup()
        setShowDetials(false);
        setSelectedApprovId(tipId)
        setshowApprovePopup(true)
        settipStatus(status)
        setclickedApproveAll(false)
    };
    // Delete conform popup open 
    const deleteConform = (item: any) => {
        setDeleteItem(item)
        setShowDeletePopup(true)
    }
    // success handle for delete
    const successHandle = () => {
        let obj = {
            "moderationId": deleteItem.toString()
        }
        deleteModeration(obj).then((response: any) => {
            if (response.status) {
                showToastSuccess(response.message)
                getModerationListData();
                setShowDeletePopup(false)
                setSelectedOption('')
                setSelectedItems([])
            } else {
                showToastSuccess(response.message)
            }

        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong)
            setShowDeletePopup(false)
        })
    }

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(moderationData.map((item: any) => item.moderationId));
        }
        setSelectAll(!selectAll);
    };

    const handleSelectItem = (moderationId: any) => {
        if (selectedItems.includes(moderationId)) {
            setSelectedItems(selectedItems.filter((id: any) => id !== moderationId));
        } else {
            setSelectedItems([...selectedItems, moderationId]);
        }
    };

    const isChecked = (moderationId: any) => selectedItems?.includes(moderationId);
    const [clickedApproveAll, setclickedApproveAll] = useState(false)
    const approveAll = () => {
        if (selectedItems.length > 0) {
            setSelectedApprovId(selectedItems.toString())
            setshowApprovePopup(true)
            setclickedApproveAll(true)

        }
    }
    const deleteAll = () => {
        if (selectedItems.length > 0) {
            setDeleteItem(selectedItems.toString())
            setShowDeletePopup(true)
        }

    }
    return (
        <div className="container-fluid px-3">
            {!showDetails &&
                <div>
                    {hideHeader &&
                        <div>
                            <div className="d-flex justify-content-between align-items-center shadow-sm bg-white rounded border p-3" >
                                <h3 className="fw-bold mb-0" >Moderation </h3>
                            </div>
                            <div className="d-flex justify-content-between  my-3 bg-white rounded p-2 resourcelist-tab-panel">
                                <div className="d-flex justify-content-end mt-0 align-items-center">
                                    <div className={` p-3 fs-sm py-2 border text-center me-2 w-[160px] cursor-pointer  rounded ${selectedListType == 'moderation' ? 'bg-secondary text-white shadow-sm' : ' fw-normal'}`} onClick={() => { listMethod('moderation'); setPageNumber(1); setSelectedOption(''); setSearchTerm(''); setSelectedItems([]); }}> Moderation</div>
                                    <div className={`p-3  fs-sm py-2 border text-center w-[160px] cursor-pointer rounded  ${selectedListType === 'flagged' ? ' bg-secondary text-white shadow-sm' : ' fw-normal'}`} onClick={() => { listMethod('flagged'); setSortValue(''); setSearchTerm(''); setSelectAll(false) }} > Flagged</div>
                                </div>
                                <div className="d-flex justify-content-end mt-0 align-items-center">
                                    <div className="me-2  smart-dropdown">
                                    </div>
                                    {selectedListType == 'moderation' &&
                                        <div className="position-relative smart-dropdown">
                                            <Dropdown options={options} onChange={handleChange} value={selectedOption} placeholder="Filter" />
                                            <BsFilterLeft className="position-absolute start-0 top-50 translate-y-[-50%] text-gray ms-2" /> </div>}
                                </div>
                            </div>
                        </div>}

                    {selectedListType == 'moderation' &&

                        <div>
                            <div className="d-flex justify-content-between mb-2 bg-white rounded p-2">
                                <div className="w-100 d-flex align-items-center">
                                    <div className="d-flex align-items-center mb-0 me-2 border rounded-2 p-1 px-2 cursor-pointer w-[100px] justify-content-center" onChange={handleSelectAll}>
                                        <input type="checkbox" checked={selectAll} className="me-1 mt-0 w-[18px] h-[18px] cursor-pointer form-check-input" />
                                        <span className="d-inline-block text-sm pt-1 font-normal">Select All</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-0 border rounded-2 p-1 px-2 cursor-pointer w-[100px] justify-content-center" onClick={deleteAll}>
                                        <span className="me-1 cursor-pointer" title="Delete All"><RiDeleteBinLine size={16} className="text-danger" /></span>
                                        <span className="d-inline-block text-sm pt-1 font-normal">Delete All</span>
                                    </div>
                                </div>
                                <span className="me-0 cursor-pointer d-inline-block" > <button className="btn  btn-primary text-white w-[140px] text-sm" onClick={approveAll}>Approve All?</button> </span>
                            </div>
                            <><div className="smart-table overflow-x-auto shadow-sm border rounded mxh-calc-379">

                                <table className="table table position-relative my-0">
                                    <thead className="th-pattenblue">

                                        <tr className="fw-bold sticky top-0 z-[9] bg-white">
                                            <th></th>
                                            <th scope="col" className="w-[300px]">
                                                <div onClick={() => toggleOrder('title')} title={orderBytitle === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}>
                                                    <div className="d-flex align-items-center cursor-pointer">Title <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /> </div>
                                                </div>
                                            </th>
                                            <th className="w-[195px]" >Reason</th>
                                            <th className="w-[170px]"><div className="d-flex align-items-center cursor-pointer text-nowrap">Status</div></th>
                                            <th className="w-[195px]" onClick={() => toggleOrder('topicNames')} title={orderBytopicNames === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}><div className="d-flex align-items-center cursor-pointer text-nowrap">Topic <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /></div></th>
                                            <th scope="col"> Submitted by </th>
                                            <th scope="col" onClick={() => toggleOrder('CreatedDate')} title={orderByCreatedDate === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}> <div className="d-flex align-items-center cursor-pointer text-nowrap">Created Date <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /></div></th>
                                            <th scope="col">Action </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {moderationData?.length > 0 ?
                                            moderationData?.map((item: any, index: any) => (
                                                <tr key={index}  >
                                                    <td>{moderationData?.length > 0 && <input
                                                        type="checkbox"
                                                        checked={isChecked(item?.moderationId)}
                                                        onChange={() => handleSelectItem(item?.moderationId)}
                                                        className="cursor-pointer me-2 form-check-input"
                                                    />}
                                                    </td>
                                                    <td className="cursor-pointer  hover:text-primary" title={item?.Title} onClick={() => showItemDetails(item?.moderationId, item?.status)} >
                                                        {item?.Title?.length > 35 ? item?.Title?.slice(0, 35) + '...' : item?.Title}</td>
                                                    <td title={item?.reason}>{item?.reason?.length > 20 ? item?.reason?.slice(0, 20) + '...' : item?.reason}</td>
                                                    <td>  {item.status == 1 ? 'Approved' : item.status == 2 ? 'Rejected' : item.status == 3 ? 'Pending' : null}</td>
                                                    <td title={item?.topicNames?.map((topic: any) => topic?.TopicName).join(', ')}>
                                                        {(() => {
                                                            const topics = item?.topicNames?.map((topic: any) => topic?.TopicName).join(', ');
                                                            const maxLength = 20;
                                                            return topics?.length > maxLength ? `${topics.slice(0, maxLength)}...` : topics;
                                                        })()}
                                                    </td>
                                                    <td>{item?.submittedBy}</td>
                                                    <td>{item?.CreatedDate ? formatDates(item?.CreatedDate) : '-'}</td>
                                                    <td className="actions-column ">
                                                        <div className="d-flex justify-content-start align-items-center position-relative" ref={(el: any) => (dropdownRefs.current[index] = el)}>
                                                            <div className="ml-[20px] cursor-pointer" onClick={() => { setSelectedItems([]); setSelectAll(false); }}>


                                                                <FaEllipsisV className="font-size-10 cursor-pointer" onClick={() => OpenActionArea(index)} />
                                                                <div className={`custom-dropdown-menu bg-white position-absolute border ml-[-121px] w-[130px] shadow-sm mt-[20px] top-0 z-[9] ${actionIndex === index ? '' : 'd-none'}`}>
                                                                    <div className="dropdown-item cursor-pointer py-2" onClick={() => showItemDetails(item?.moderationId, item?.status)}>View</div>
                                                                    <div className="dropdown-item cursor-pointer py-2" onClick={() => approveTip(item?.moderationId, item?.status)}>Approve/Reject</div>
                                                                    <div className="dropdown-item cursor-pointer py-2" onClick={() => deleteConform(item?.moderationId)}>Delete</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr><td colSpan={12}><h6 className="fw-bold mt-3 d-flex justify-content-center">No data found </h6> </td></tr>}
                                    </tbody>
                                </table>
                            </div>
                                <div className="d-flex justify-content-end mt-2 ">
                                    <div className="bg-white rounded p-2 shadow-sm z-[99] z-intial">
                                        <Pagination
                                            count={totalPages}
                                            variant="outlined"
                                            shape="rounded"
                                            page={pageNumber}
                                            onChange={handlePageChange}
                                            siblingCount={1} // Adjust as necessary
                                            boundaryCount={1} // Adjust as necessary
                                            showFirstButton
                                            showLastButton />
                                    </div>
                                </div>
                            </>
                        </div>
                    }
                </div>
            }
            {selectedListType == 'flagged' && <FlaggedList searchTerm={searchTerm} selectedCategoryOption={selectedOption} hideHeaderInView={setHIdeHeader} />}
            {showDetails && <ModerationDetials selectedItem={selectedItem} setShowPostDetails={setShowDetials} setpageNumber={setPageNumber} setFilterValue={setSelectedOption} setSelectedItems={setSelectedItems} setSearchTerm={setSearchTerm} getModerationListData={getModerationListData} selectedOption={setSelectedOption} setSelectAll={setSelectAll} />}
            {deletePopup && <DeleteConformation actionType="Delete" title="Delete Conformation" closeModal={setShowDeletePopup} successHandle={successHandle} />}
            {showApprovePopup && <ApproveTipPopup selectedApproveId={selectedApproveId} setshowApprovePopup={setshowApprovePopup} getListData={getModerationListData} tipStatus={tipStatus} isFromMod={true} selectAll={clickedApproveAll} setSelectedItems={setSelectedItems} />}

        </div>
    )
}