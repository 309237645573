import { httpService } from "./api/Api";
import axios from "axios";


// To get contribute list
export const getContributeList = (obj: any) => {
    return httpService.post('Contribute/GetAllTipsLog', obj);
}

// To Approve tip 
export const approveOrReject = (id: any) => {
    return httpService.post('Contribute/ApproveOrRejectTip', id);
}
// getTipDetailsByID 
export const getTipDetailsByID = (id:any) => {
    return httpService.get('Contribute/GetTipsById'+'/' + id);
}
// TO delete the tip
export const deleteTip = (obj: any) => {
    return httpService.post('Contribute/DeleteTip', obj);
}
// To update the resource
export const UpdateTip = (obj: any) => {
    return httpService.postFile('Contribute/UpdateTipDetails', obj);
}
export const deleteTipFile = (obj: any) => {
    return httpService.post('Contribute/DeleteTipLinkById', obj);
}

// CONTRIBUTE METRICS
// TO get Dashboard Trending topics api 
export const GetTrendingTopics = (obj:any) => {
    return httpService.post('Contribute/GetTrendingTipsTopics',obj);
}

// TO get Dashboard users api 
export const GetTrendingTips = (obj:any) => {
    return httpService.post('Contribute/GetTrendingTips',obj);
}

export const timelineChart = (obj: any) => {
    return httpService.post('Contribute/GetTimelineChart', obj);
}
export const getPieChart = () => {
    return httpService.get('Contribute/GetUserEngagementChart');
}
