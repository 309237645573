import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";
import Maintenance from "../../components/Maintenance";
import ReactDOM from "react-dom";
import App from "../../App";

interface HttpHeaders {
    Accept?: any;
    "Content-Type": any;
    Authorization?: string; // Add Authorization header for bearer token
}

interface FileHeaders {
    "Content-Type": any;
}

type Methods = "head" | "options" | "put" | "post" | "patch" | "delete" | "get";
const Window = (window as any).config;
class HttpService {
    apiBaseURL: any = Window.API_URL;
    apiBaseURLConversation: any =  Window.API_Conversation_URL;

    private headers: HttpHeaders = {
        "Content-Type": "application/json",
    };
    private fileHeaders: FileHeaders = {
        "Content-Type": "multipart/form-data",
    };

    constructor() {
        this.setupInterceptors();

    }

    private setupInterceptors() {

        // Request interceptor
        axios.interceptors.request.use(
            (config: any) => {
                // Modify request config if needed
                // For example, you can add headers, authentication tokens, etc.
                config.headers = { ...this.headers, ...config.headers };

                // Add bearer token if available
                const authToken: any = localStorage.getItem('authToken');
                if (authToken) {
                    config.headers.Authorization = `Bearer ${authToken}`;
                }

                // const authToken:any = ""
                // Set timeout for the session (in milliseconds)
                config.timeout = 30000; // Adjust the timeout as needed

                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        // Response interceptor
        axios.interceptors.response.use(
            (response: AxiosResponse) => {
                // Modify response data if needed
                // For example, you can parse the response, handle common errors, etc.
                return response;
            },
            (error: AxiosError) => {
                // Handle errors globally
                if (error.response) {
                    if (error.response.status == 400) {
                        throw error.response.data;
                        // session expire.
                    } else if (error.response.status == 401) {
                        localStorage.clear();
                        window.location.href = '/signin'; // Redirect to login page
                        // serve undermaintaines .
                    } else if (error.response.status == 502) {
                        this.renderMaintenanceComponent();
                        return Promise.reject(error);
                    } else {
                        const message = {
                            title: "Server Error",
                            content: "Please try again later",
                        };
                        error.response.data = { message };
                        throw error.response.data;
                    }
                } else {
                    throw error;
                }
            }
        );
    }

    async get<T>(url: any, queryParams?: any, customHeaders?: HttpHeaders): Promise<T> {
        const headers: HttpHeaders = { ...this.headers, ...(customHeaders || {}) };
        try {
            const response: AxiosResponse<T> = await this.makeRequest('get', url, { headers, params: queryParams });
            return response.data;
        } catch (error: any) {
            throw this.handleAxiosError(error);
        }
    }

    async post<T>(url: any, data: any, queryParams?: any, customHeaders?: HttpHeaders, mediaType?: any): Promise<T> {
        const headers: HttpHeaders = { ...this.headers, ...(customHeaders || {}) };
        const responseType = mediaType ? { responseType: 'blob' } : undefined;
        try {
            const response: AxiosResponse<T> = await this.makeRequest('post', url, data, { headers, ...responseType });
            return response.data;
        } catch (error: any) {
            throw this.handleAxiosError(error);
        }
    }
   
    async put<T>(url: any, data: any, queryParams?: any, customHeaders?: HttpHeaders, mediaType?: any): Promise<T> {
        const headers: HttpHeaders = { ...this.headers, ...(customHeaders || {}) };
        try {
            const response: AxiosResponse<T> = await this.makeRequest('put', url, data, { headers });
            return response.data;
        } catch (error: any) {
            throw this.handleAxiosError(error);
        }
    }

    async postFile<T>(url: any, data: any, queryParams?: any, customHeaders?: HttpHeaders): Promise<T> {
        const headers: HttpHeaders = { ...this.fileHeaders, ...(customHeaders || {}) };
        try {
            const response: AxiosResponse<T> = await this.makeRequest('post', url, data, { headers });
            return response.data;
        } catch (error: any) {
            throw this.handleAxiosError(error);
        }
    }
     //post method to get conversation count from another api url
     async postConversation<T>(url: any, data: any, queryParams?: any, customHeaders?: HttpHeaders, mediaType?: any): Promise<T> {
        const headers: HttpHeaders = { ...this.headers, ...(customHeaders || {}) };
        const responseType = mediaType ? { responseType: 'blob' } : undefined;
        try {
            const response: AxiosResponse<T> = await this.makeRequestConversation('post', url, data, { headers, ...responseType });
            return response.data;
        } catch (error: any) {
            throw this.handleAxiosError(error);
        }
    }
    private async makeRequestConversation<T>(method: Methods, url: any, data: any, config: any = {}): Promise<AxiosResponse<T>> {
        try {
            const response: AxiosResponse<T> = await axios[method](this.apiBaseURLConversation + url, data, config);
            return response;
        } catch (error) {
            throw error;
        }
    }

    private async makeRequest<T>(method: Methods, url: any, data: any, config: any = {}): Promise<AxiosResponse<T>> {
        try {
            const response: AxiosResponse<T> = await axios[method](this.apiBaseURL + url, data, config);
            return response;
        } catch (error) {
            throw error;
        }
    }

    private handleAxiosError(error: AxiosError): any {
        if (error.response) {
            if (error.response.status >= 400 && error.response.status < 503) {
                // throw error.response.data;
            } else {
                const message = {
                    title: "Server Error",
                    content: "Please try again later",
                };
                error.response.data = { message };
                throw error.response.data;
            }
        } else {
            throw error;
        }
    }

    private renderMaintenanceComponent() {
        // Logic to render your Maintenance component
        // You can customize this according to your application structure
        // For example:
        // <Maintenance/>
        const authToken: any = localStorage.getItem('authToken');
        if (authToken) {
            ReactDOM.render(<App />, document.getElementById('root'));
        } else {
            ReactDOM.render(<Maintenance />, document.getElementById('root'));
        }
    }

}

export const httpService = new HttpService();
