import { useCallback, useEffect, useRef, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import AddUser from "../../components/common/modals/AddUser";
import DeleteConformation from "../../components/common/modals/DeleteConformation";
import { fetchImages } from "../../constants/HandleImages";
import { ErrorMessages } from "../../constants/Labels";
import { getLoginUserID } from "../../services/AuthService";
import { deleteUser } from "../../services/UserManagementService";
import { showToastError, showToastSuccess } from "../../utils/common/ToastMessages";

interface adminProps {
    usersData?: any
    getUserListData?: any
}

export default function AdminList({ usersData, getUserListData }: adminProps) {
    const [deletePopup, setShowDeletePopup] = useState(false)
    const [deletingId, setDeletingId] = useState<any>()
    const [selectedUser, setSelectedUser] = useState('')
    const [ShowInviteUserPopup, setShowInviteUserPopup] = useState(false)
    const [actionIndex, setActionIndex] = useState<number | null>(null);
    const dropdownRefs = useRef<(HTMLDivElement | null)[]>([]);
    let userDetails = getLoginUserID();


    // To clear activity popup
    const clearActivityPopup = useCallback(() => {
        setActionIndex(null);
    }, []);
    // handle clikoutside action popup
    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (dropdownRefs.current.some(ref => ref && ref.contains(event.target as Node))) {
            return;
        }
        clearActivityPopup();
    }, [clearActivityPopup]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);
    // To open actions 
    const OpenActionArea = (index: number) => {
        setActionIndex(index);
    };

    // To set the deleting item
    const deleteItem = (index: any) => {
        setDeletingId(index)
        setShowDeletePopup(true)
    }
    // Delete resource success handle
    const deleteSuccessHandles = () => {
        let obj = {
            "UserId": deletingId,
            "ReasonForDelete": ''

        }
        deleteUser(obj).then((response: any) => {
            if (response.status) {
                showToastSuccess(response.message)
                getUserListData()
                setShowDeletePopup(false)
            } else {
                showToastSuccess(response.message)
            }

        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong)
            setShowDeletePopup(false)
        })
    }
    // To set the editing user
    const editUser = (item: any) => {
        setSelectedUser(item)
        setShowInviteUserPopup(true)
    }

    return (
        <div className="smart-table overflow-x-auto shadow-sm border rounded mxh-calc-321">
            <table className="table table position-relative my-0">
                <thead className="th-pattenblue">
                    <tr className="fw-bold sticky top-0 z-[9] bg-white">
                        <th className="w-[400px]">Admin</th>
                        <th className="w-[400px]"> Email</th>
                        <th>Status </th>
                        <th>Action </th>
                    </tr>
                </thead>
                <tbody>
                    {usersData?.length > 0 ?
                        usersData?.map((item: any, index: any) => (
                            <tr key={index} className={`${usersData?.length == 1 ? 'moreoptions-position-fixed  ' : ''}`}>
                                <td className="!w-[400px]">
                                    <div className="text-truncate w-[400px]">{item.Name}</div></td>
                                <td className="!w-[400px]"><div className="text-truncate w-[400px]">{item.Email}</div></td>
                                <td className="text-start ps-4">
                                    <div className="pl-[20px]">{(item.IsActive == true && item?.AdminAccess == true) ? <img src={fetchImages.activeImg} className="" height={12} width={12} title="Active" /> : <img src={fetchImages.inactiveImg} className="" height={12} width={12} title="Inactive" />}
                                    </div></td>

                                <td className="actions-column dropdown-with-two-options">
                                    <div className="d-flex justify-content-between align-items-center" ref={(el: any) => (dropdownRefs.current[index] = el)}>
                                        {(item.IsActive == true && item?.AdminAccess == true) ?
                                            <div className="pl-[20px] position-relative">
                                                <FaEllipsisV className="font-size-10 cursor-pointer" onClick={() => OpenActionArea(index)} /><div className={`custom-dropdown-menu bg-white position-absolute border ml-[-108px] w-[120px] shadow-lg mt-[18px] top-0 z-[9]  ${actionIndex === index ? '' : 'd-none'}`} >
                                                    <div className="dropdown-item cursor-pointer py-2" onClick={() => editUser(item)}>Edit</div>
                                                    {userDetails?.userId !== item.UserID && <div className="dropdown-item cursor-pointer py-2" onClick={() => deleteItem(item.UserID)}>Delete</div>}
                                                </div></div>
                                            : ' '}
                                    </div>
                                </td>
                            </tr>
                        )) : <tr><td colSpan={12}><h6 className="fw-bold mt-3 d-flex justify-content-center">No data found </h6> </td></tr>}
                </tbody>
            </table>

            {deletePopup && <DeleteConformation successHandle={deleteSuccessHandles} actionType='Delete' title='Delete Conformation' closeModal={setShowDeletePopup} />}
            {ShowInviteUserPopup && <AddUser setShowInviteUserPopup={setShowInviteUserPopup} getUserListData={getUserListData} selectedUser={selectedUser} />}

        </div>



    )


}