import { useEffect, useRef, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { FaUserLarge } from "react-icons/fa6";
import { IoArrowBackOutline } from "react-icons/io5";
import { TiAttachment } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import ApproveTipPopup from "../../components/common/modals/ApprovePopup";
import DeleteConformation from "../../components/common/modals/DeleteConformation";
import { ErrorMessages } from "../../constants/Labels";
import { deleteTip } from "../../services/ContributeService";
import { handleClickOutside } from "../../utils/common/CommonUtility";
import { formatDates } from "../../utils/common/Format";
import { showToastError, showToastSuccess } from "../../utils/common/ToastMessages";

interface PopupProps {
    selectedItem?: any;
    setshowTipDetails?: any;
    setpageNumber?: any
    setFilterValue?: any
    getContributeListData?: any
    tipStatus?: any
    setSearchTerm?: any

}

export default function ViewContributeTip({ selectedItem, setshowTipDetails, tipStatus, setpageNumber, setFilterValue, getContributeListData, setSearchTerm }: PopupProps) {
    const [actionIndex, updateActivityIndex] = useState<number | null>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [selectedApproveId, setSelectedApprovId] = useState()
    const [showApprovePopup, setshowApprovePopup] = useState(false)
    const [deletePopup, setShowDeletePopup] = useState(false)
    const [deletingIndex, setDeletingIndex] = useState<any>()
    const navigate = useNavigate()


    // Function to clear the activity popup
    const clearActivityPopup = () => {
        updateActivityIndex(null);
    }
    // open actions
    const OpenActionArea = (selectedIndex: number) => {
        updateActivityIndex(selectedIndex);
    };

    useEffect(() => {
        const cleanupClickOutside = handleClickOutside(dropdownRef, clearActivityPopup);
        return () => {
            cleanupClickOutside();
        };
    }, []);

    const approveTip = (selectedTipId: any) => {
        setSelectedApprovId(selectedTipId)
        setshowApprovePopup(true)
    };

    // To open delete Tip popup
    const deleteItem = (index: any) => {
        setDeletingIndex(index)
        setShowDeletePopup(true)
    }
    const deleteSuccessHandle = () => {
        let obj = {
            "tipId": deletingIndex
        }
        deleteTip(obj).then((response: any) => {
            if (response.status) {
                showToastSuccess(response.message)
                getContributeListData();
                setShowDeletePopup(false)
                setshowTipDetails(false)
            } else {
                showToastSuccess(response.message)
            }

        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong)
            setShowDeletePopup(false)
        })
    }

    return (
        <div>
            <div className='p-4 bg-white border rounded-md mih-calc-85'>
                <div className="  mb-0">
                    <div className="mb-4">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center mb-0">
                                <IoArrowBackOutline
                                    size={20}
                                    className="me-2  cursor-pointer"
                                    onClick={() => { setshowTipDetails(false); setFilterValue(''); setpageNumber(1); setSearchTerm('') }}
                                />
                                <h3 className="fw-bold my-0 pt-1">Tip Details</h3>
                            </div>
                            <div>
                                <div className="d-flex justify-content-between align-items-center" ref={dropdownRef}>
                                    <FaEllipsisV className="font-size-10 cursor-pointer text-gray" onClick={() => OpenActionArea(selectedItem)} />
                                    <div className={`custom-dropdown-menu position-absolute border mt-[60px] shadow-sm bg-white ml-[-109px] rounded-md w-[120px] ${actionIndex === selectedItem ? '' : 'd-none'}`} >
                                        <div className="dropdown-item cursor-pointer" onClick={() => { setshowTipDetails(false); navigate('/contribute/EditContributeTip', { state: selectedItem?.tipId }) }}>Edit</div>
                                        <div className="dropdown-item cursor-pointer my-0 py-2 leading-4 " onClick={() => deleteItem(selectedItem?.tipId)}>Delete</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" ms-0">
                        <div className="d-flex justify-content-between align-items-center mb-4 custom-bg-body p-2 rounded-md">
                            <div className="d-flex align-items-center ">
                                <div className="w-[50px] h-[50px] me-3 d-flex justify-content-center align-items-center bg-white p-0 rounded-circle">
                                    {selectedItem?.uploadedByImage ? <img src={selectedItem?.uploadedByImage} className="img-fluid w-full !h-full rounded-circle object-cover" /> : <div className="bg-white  w-100 h-100 rounded-circle d-flex justify-content-center align-items-center"><FaUserLarge size={20} className="me-0" /></div>}

                                </div>
                                <div className="d-block">
                                    <label className="text-primary mb-0 ">Uploaded by</label>
                                    <h5 className="fw-bold mb-0 ">{selectedItem?.submittedBy}</h5>
                                </div>
                            </div>
                            <div className="d-flex align-items-start flex-column">
                                <label className="mb-0 fs text-md text-primary">Uploded Date</label>
                                <h5 className="font-normal mb-1 text-dark text-base ">{formatDates(selectedItem?.submittedDateTime)} </h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-2">
                                    <label className="mb-1 text-base text-dark fw-bold">Title:</label>
                                    <h5 className="font-normal mb-1 text-dark text-base">{selectedItem?.title}:</h5>
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <div className=" w-100  ">
                                    <div className="">
                                        <div className="d-block text-dark mb-1 text-base fw-bold">Description:</div>
                                        <div className="border p-3 rounded-md">
                                            <div className="max-h-[200px] overflow-y-auto ">
                                                <span
                                                    className="text-base text-dark ul-li-list-normal "
                                                    dangerouslySetInnerHTML={{ __html: selectedItem?.description }}
                                                ></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-2">
                                <div className="w-100 ">
                                    <div className="w-calc-5px">
                                        <div className="mb-3">
                                            <div className="d-block text-dark mb-0 text-base fw-bold">Topics:</div>
                                            <span className="text-base text-gray">{selectedItem?.topicNames?.map((topic: any) => topic.TopicName).join(', ')}</span>
                                        </div>
                                        <div className="mb-4 d-flex justify-content-start flex-column">
                                            <div className="d-block text-dark mb-0 text-base fw-bold">Sensitive Warning:</div>

                                            <input
                                                type="checkbox"
                                                checked={selectedItem?.isSensitive}
                                                className="form-check-input"
                                                disabled
                                            />
                                        </div>
                                        <div className="mb-0 d-flex justify-content-between">
                                            <div className="">
                                                <span className="d-block text-dark mb-0 text-base fw-bold">Up Votes</span>
                                                <div className="d-flex mx-auto justify-content-center mt-1">
                                                    <div className="text-base text-primary text-center bg-whitesmoke bg-opacity-25 p-2 w-[30px] h-[30px] rounded-circle d-flex align-items-center justify-content-center fw-bold">{selectedItem?.upvotesCount}</div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <span className="d-block text-dark mb-0 text-base fw-bold">Bookmark Count</span>
                                                <div className="d-flex mx-auto justify-content-center mt-1">
                                                    <div className="text-base text-primary text-center bg-whitesmoke bg-opacity-25 p-2 w-[30px] h-[30px] rounded-circle d-flex align-items-center justify-content-center fw-bold">  {selectedItem?.BookMarkCount} </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">


                                <div className="my-3">
                                    <strong className="fw-bold text-dark text-base fw-bold mb-1 d-block">Links:</strong>
                                    <div className="d-flex justify-content-start bg-whitesmoke p-2 rounded-md ">
                                        {selectedItem?.link ? <> <TiAttachment size={20} className="text-primary me-2" /> <a href={selectedItem?.link} target="_blank" className="cursor-pointer text-dark">{selectedItem.link}</a>
                                        </> : 'N/A'}

                                    </div>
                                </div>
                                <div className="mb-2">
                                    <strong className="fw-bold text-dark text-base text-gray mb-2 d-block">Tags:</strong>
                                    <div className="border p-3 rounded-md">
                                        {selectedItem?.tagNames?.length > 0 ? (
                                            selectedItem?.tagNames?.map((tag: any, index: number) => (
                                                <span key={index} className="d-inline-block  mb-2 text-base text-dark text-sm border me-2 rounded-md p-2 bg-whitesmoke " >
                                                    {tag.TagName}
                                                </span>
                                            ))) : "N/A"}

                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end mt-5">
                                {tipStatus == 1 ? (<button className="btn btn-sm btn-danger text-white text-md w-[102px]" onClick={() => approveTip(selectedItem?.tipId)} >Reject</button>) : tipStatus == 2 ? (<button className="btn btn-primary px-4 text-white text-md w-[102px]" type="button" onClick={() => approveTip(selectedItem?.tipId)}> Approve </button>) : tipStatus == 3 ? (
                                    <button type="button" className='btn btn-primary btn-sm  w-[150px] text-white text-md ' onClick={() => approveTip(selectedItem?.tipId)}>  Approve / Reject</button>) : null}
                            </div>
                        </div>
                    </div>
                    {showApprovePopup && <ApproveTipPopup selectedApproveId={selectedApproveId} setshowApprovePopup={setshowApprovePopup} setshowTipDetails={setshowTipDetails} getListData={getContributeListData} tipStatus={tipStatus} isFromViewPage={true} />}
                    {deletePopup && <DeleteConformation successHandle={deleteSuccessHandle} actionType='Delete' title='Delete Conformation' closeModal={setShowDeletePopup} />}
                </div>
            </div>
        </div>
    );
}
