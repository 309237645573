const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex?.test(email);
};

const isNotEmptyString = (input: string): boolean => {
    return input?.trim() !== '';
};
const notAllowSpaces = (input: string, event: any): void => {
    if (event?.key === ' ' && input?.trim() === '') {
        event.preventDefault(); // Prevent space if input is empty
    }
};

const isStringLengthExceeded = (input: string, maxLength: number): boolean => {
    return input?.length >= maxLength;
};

const isPasswordStrong = (password: string): boolean => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()]).{8,}$/;
    return passwordRegex.test(password);
};

const isValidMobileNumber = (mobileNumber: any): boolean => {
    const mobileNumberPattern = /^\d{10}$/;
    return mobileNumberPattern.test(mobileNumber);
}

export { isNotEmptyString, isPasswordStrong, isStringLengthExceeded, isValidEmail, isValidMobileNumber, notAllowSpaces };

