import { httpService } from "./api/Api";



// To Add user
export const inviteUser = (obj: any) => {
    return httpService.post('User/InviteUser', obj);
}
// To update user
export const updateUser = (obj: any) => {
    return httpService.post('UserManagement/UpdateUserData', obj);
}
//Get users list
export const getUserList = (obj: any) => {
    return httpService.post('UserManagement/GetUserList', obj)
}
// To delete user'
export const deleteUser = (id: any) => {
    return httpService.post('UserManagement/DeleteUser', id);
}
// To delete App user'
export const deleteAppUser = (id: any) => {
    return httpService.post('User/DeleteAppUserAccount', id);
}
//user survey popup '
export const submitSurveyForm = (id: any) => {
    return httpService.post('UserManagement/SaveFeedbackTemplates', id);
}
// UserManagement/GetUserFeedbackById
export const getUserServeyForms = (id: any) => {
    return httpService.get('UserManagement/GetUserFeedbackById' + '/' + id);
}

// Survey apis

export const getTemplatesList = (obj: any) => {
    return httpService.post('UserManagement/GetAdminFeedbackQuestionTemplates', obj);
}
// To get users based on selected tempid
export const getUserListByTempId = (obj: any) => {
    return httpService.post('UserManagement/GetUserFeedbackList', obj);
}

export const getOptedAnsByUserId = (userid: any, UserFeedbackId: any) => {
    return httpService.get('UserManagement/GetUserFeedbackById' + '/' + userid + '/' + UserFeedbackId);
}


