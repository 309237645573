import { Pagination } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { PiArrowsDownUpFill } from "react-icons/pi";
import { RiDeleteBinLine } from "react-icons/ri";
import DeleteConformation from "../../components/common/modals/DeleteConformation";
import { ErrorMessages } from "../../constants/Labels";
import { deleteFlaggedItem, getFlaggedDetailsByID, getFlaggedList } from "../../services/ModerationService";
import { formatDates } from "../../utils/common/Format";
import { showToastError, showToastSuccess } from "../../utils/common/ToastMessages";
import FlaggedDetails from "./FlaggedDetials";


interface props {
    searchTerm?: any
    selectedCategoryOption?: any
    hideHeaderInView?: any
}

export default function FlaggedList({ searchTerm, selectedCategoryOption, hideHeaderInView }: props) {
    const dropdownRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [pageNumber, setPageNumber] = useState<any>(1);
    const [totalCount, setTotalCount] = useState<any>(0);
    const [tipStatus, settipStatus] = useState()
    const [selectedItem, setSelectedItem] = useState<any>()
    const [showViewDetials, setshowViewDetails] = useState<any>(false)
    const recordsPerPage = 10; // Number of records per page
    const [sortValue, setSortValue] = useState('')
    const [orderBy, setOrderBy] = useState<any>('Asc');
    const [flaggedListeData, setFlaggedData] = useState([])
    const [orderBytopicNames, setOrderBytopicNames] = useState('Asc')
    const [orderBytitle, setOrderBytitle] = useState('Asc')
    const [orderByViews, setOrderByViews] = useState('Asc')
    const [actionIndex, setActionIndex] = useState<number | null>(null);
    const [orderByDate, setorderByDate] = useState('Asc')
    const [orderByFlagged, setorderByFlagged] = useState('Asc')
    const [deletePopup, setShowDeletePopup] = useState(false)
    const [deletingIndex, setDeletingIndex] = useState<any>()



    // To clear activity popup
    const clearActivityPopup = useCallback(() => {
        setActionIndex(null);
    }, []);
    // handle clikoutside action popup
    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (dropdownRefs.current.some((ref: any) => ref && ref.contains(event.target as Node))) {
            return;
        }
        clearActivityPopup();
    }, [clearActivityPopup]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);
    // To open actions 
    const OpenActionArea = (index: number) => {
        setActionIndex(index);
    };

    // TO get the list data
    const getContributeListData = () => {
        let obj = {
            "pageNumber": pageNumber,
            "pageSize": 10,
            'filterBy': selectedCategoryOption,
            "sortColumnName": sortValue,
            "sortColumnDirection": orderBy,
        }
        getFlaggedList(obj).then((response: any) => {
            if (response?.status) {
                setFlaggedData(response.moderationList[0].FlaggedContent)
                setTotalCount(response.moderationList[0].TotalCount[0].NoOfRecords);
            } else {
                showToastError(response?.message)
            }

        }).catch(() => {
            showToastError(ErrorMessages?.SomethingWrong)
        })
    }

    useEffect(() => {
        getContributeListData();
    }, [pageNumber, orderBy, selectedCategoryOption, searchTerm]);

    // Pagination
    const handlePageChange = (event: any, value: any) => {
        setPageNumber(value);
    };
    const totalPages = Math.ceil(totalCount / recordsPerPage);
    //set sorting Order of all table values  
    const toggleOrder = (type: 'topicNames' | 'title' | 'views' | 'date' | 'flagged') => {
        if (type === 'views') {
            const newOrder = orderByViews === 'Asc' ? 'Desc' : 'Asc';
            setOrderByViews(newOrder);
            setSortValue('ViewCount')
            setOrderBy(newOrder)
        }
        else if (type === 'topicNames') {
            const newOrder = orderBytopicNames === 'Asc' ? 'Desc' : 'Asc';
            setOrderBytopicNames(newOrder);
            setSortValue('Topics')
            setOrderBy(newOrder)
        }
        else if (type === 'title') {
            const newOrder = orderBytitle === 'Asc' ? 'Desc' : 'Asc';
            setOrderBytitle(newOrder);
            setSortValue('title')
            setOrderBy(newOrder)
        }
        else if (type === 'date') {
            const newOrder = orderByDate === 'Asc' ? 'Desc' : 'Asc';
            setorderByDate(newOrder);
            setSortValue('CreatedDate')
            setOrderBy(newOrder)
        }
        else if (type === 'flagged') {
            const newOrder = orderByFlagged === 'Asc' ? 'Desc' : 'Asc';
            setorderByFlagged(newOrder);
            setSortValue('FlaggedCount')
            setOrderBy(newOrder)
        }
    };

    const handleItemClick = () => {
        hideHeaderInView(false);
    };
    // To open edit popup and get data by id api
    const viewDetails = (itemId: any, type: any) => {
        clearActivityPopup()
        getFlaggedDetailsByID(itemId, type).then((response: any) => {
            if (response.status) {
                setSelectedItem(response.moderationDetails[0])
                setshowViewDetails(true)
            } else {
                showToastError(response?.message)
                setshowViewDetails(false)

            }

        }).catch((error: any) => {
            showToastError(ErrorMessages.SomethingWrong)
        })
    }
    // To open delete Tip popup
    const [contentType, setContentType] = useState<any>()
    const deleteItem = (id: any, type: any) => {
        setDeletingIndex(id)
        setShowDeletePopup(true)
        setContentType(type)
    }

    const deleteSuccessHandle = () => {
        let deleteFlagged: any[] = [];
        if (selectedItems.length > 1) {
            deleteFlagged = selectedItems.map(item => ({
                contentID: item?.ID?.toString(),
                contentType: item?.ContentType
            }));
        } else {
            deleteFlagged = [{
                contentID: deletingIndex?.toString(),
                contentType: contentType
            }];
        }

        // Send the payload with the array wrapped in 'contentAndType' to the API
        const payload = { deleteFlagged };

        deleteFlaggedItem((payload)).then((response: any) => {
            if (response.status) {
                showToastSuccess(response.message);
                getContributeListData();
                setShowDeletePopup(false);
                hideHeaderInView(true);
            } else {
                showToastSuccess(response.message);
            }
        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong);
            setShowDeletePopup(false);
        });
    };

    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(flaggedListeData.map((item: any) => ({
                ID: item.ID,
                ContentType: item.ContentType
            })));
        }
        setSelectAll(!selectAll);
    };

    const handleSelectItem = (ID: any, ContentType: any) => {
        const exists = selectedItems.find((item: any) => item.ID === ID);
        if (exists) {
            setSelectedItems(selectedItems.filter((item: any) => item.ID !== ID));
        } else {
            setSelectedItems([...selectedItems, { ID, ContentType }]);
        }
    };
    // The !! converts the result of find into a boolean:
    const isChecked = (ID: any) => !!selectedItems.find((item: any) => item.ID === ID);

    const deleteAll = () => {
        if (selectedItems.length > 0) {
            setDeletingIndex(selectedItems[0]?.ID)
            setShowDeletePopup(true)
            setContentType(selectedItems[0]?.ContentType)
        }

    }

    return (
        <div className="container-fluid px-0">
            {!showViewDetials &&
                <div>
                    <div className="d-flex justify-content-between mb-2 bg-white rounded p-2 h-[51px]">
                        <div className="w-100 d-flex align-items-center">
                            <div className="d-flex align-items-center mb-0 me-2 border rounded-2 p-1 px-2 cursor-pointer w-[100px] justify-content-center" onChange={handleSelectAll} >
                                <input type="checkbox" checked={selectAll} className="me-1 mt-0 w-[18px] h-[18px] cursor-pointer form-check-input" />
                                <span className="d-inline-block text-sm pt-1 font-normal">Select All</span>
                            </div>
                            <div className="d-flex align-items-center mb-0 border rounded-2 p-1 px-2 cursor-pointer w-[100px] justify-content-center" onClick={deleteAll}>
                                <span className="me-1 cursor-pointer" title="Delete All"><RiDeleteBinLine size={16} className="text-danger" /></span>
                                <span className="d-inline-block text-sm pt-1 font-normal">Delete All</span>
                            </div>
                        </div>

                    </div>
                    <><div className="smart-table overflow-x-auto shadow-sm border rounded mxh-calc-379">
                        <table className="table table position-relative my-0">
                            <thead className="th-pattenblue">
                                <tr className="fw-bold sticky top-0 z-[9] bg-white">
                                    <th></th>
                                    <th scope="col" className="w-[300px]">
                                        <div onClick={() => toggleOrder('title')} title={orderBytitle === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}>
                                            <div className="d-flex align-items-center cursor-pointer">Title <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /> </div>
                                        </div>
                                    </th>
                                    <th className="w-[195px]" onClick={() => toggleOrder('views')} title={orderByViews === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}><div className="d-flex align-items-center cursor-pointer text-nowrap">Views <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /></div></th>
                                    <th className="w-[195px]" onClick={() => toggleOrder('flagged')} title={orderByFlagged === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}><div className="d-flex align-items-center cursor-pointer text-nowrap">Flagged <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /></div></th>
                                    <th className="w-[195px]" onClick={() => toggleOrder('topicNames')} title={orderBytopicNames === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}><div className="d-flex align-items-center cursor-pointer text-nowrap">Topic <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /></div></th>
                                    <th scope="col"><div className='d-flex justify-content-start cursor-pointer align-items-center text-nowrap' onClick={() => toggleOrder('date')}>
                                        Date <span title={orderByDate === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'} className='ms-2'><PiArrowsDownUpFill className="mt-[-4px]" /></span>
                                    </div></th>
                                    <th scope="col">Action </th>

                                </tr>
                            </thead>
                            <tbody>
                                {flaggedListeData?.length > 0 ?
                                    flaggedListeData?.map((item: any, index: any) => (
                                        <tr key={index}  >
                                            <td>{flaggedListeData?.length > 0 && (
                                                <input
                                                    type="checkbox"
                                                    checked={isChecked(item?.ID)}
                                                    onChange={() => handleSelectItem(item?.ID, item?.ContentType)}
                                                    className="cursor-pointer me-2 form-check-input"
                                                />
                                            )}
                                            </td>
                                            <td className="cursor-pointer  hover:text-primary" onClick={() => { viewDetails(item?.ID, item?.ContentType); handleItemClick() }} title={item?.Title}>{item?.Title?.length > 40 ? item?.Title?.slice(0, 40) + '...' : item?.Title}</td>
                                            <td>{item?.ViewCount}</td>
                                            <td>{item?.FlaggedCount}</td>
                                            <td title={item.Topics}>
                                                {item?.Topics && item.Topics.length > 20
                                                    ? item.Topics.substring(0, 20) + '...'
                                                    : item.Topics}
                                            </td>
                                            <td>{formatDates(item?.CreatedDate)}</td>
                                            <td className="actions-column ">
                                                <div className="d-flex justify-content-start align-items-center position-relative" ref={(el: any) => (dropdownRefs.current[index] = el)}>
                                                    <div className="ml-[20px] cursor-pointer" onClick={() => { setSelectedItems([]); setSelectAll(false); }}>
                                                        <FaEllipsisV className="font-size-10 cursor-pointer" onClick={() => OpenActionArea(index)} />
                                                        <div className={`custom-dropdown-menu bg-white position-absolute border ml-[-121px] w-[130px] shadow-sm mt-[20px] top-0 z-[9] ${actionIndex === index ? '' : 'd-none'}`}>
                                                            <div className="dropdown-item cursor-pointer py-2" onClick={() => { viewDetails(item?.ID, item?.ContentType); handleItemClick() }} >View</div>
                                                            <div className="dropdown-item cursor-pointer py-2" onClick={() => { deleteItem(item?.ID, item?.ContentType) }}>Delete</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )) : <tr><td colSpan={12}><h6 className="fw-bold mt-3 d-flex justify-content-center">No data found </h6> </td></tr>}
                            </tbody>
                        </table>
                    </div>
                        <div className="d-flex justify-content-end mt-2 ">
                            <div className="bg-white rounded p-2 shadow-sm z-[99] z-intial">
                                <Pagination
                                    count={totalPages}
                                    variant="outlined"
                                    shape="rounded"
                                    page={pageNumber}
                                    onChange={handlePageChange}
                                    siblingCount={1} // Adjust as necessary
                                    boundaryCount={1} // Adjust as necessary
                                    showFirstButton
                                    showLastButton />
                            </div>
                        </div>
                    </>

                </div>
            }

            {showViewDetials && <FlaggedDetails selectedItem={selectedItem} setshowDetails={setshowViewDetails} setpageNumber={setPageNumber} getContributeListData={getContributeListData} tipStatus={tipStatus} setSearchTerm={searchTerm} hideHeaderInView={hideHeaderInView} />}
            {deletePopup && <DeleteConformation successHandle={deleteSuccessHandle} actionType='Delete' title='Delete Conformation' closeModal={setShowDeletePopup} />}

        </div>
    )



}
