import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/ui/Input";
import { ErrorMessages } from "../../constants/Labels";
import { getLoginUserID, UpdatePassword } from "../../services/AuthService";
import { showToastError, showToastSuccess } from "../../utils/common/ToastMessages";
import { isNotEmptyString, isPasswordStrong, isStringLengthExceeded, } from "../../utils/common/Validation";


interface PopupProps {
  setShowUpdatePassword?: any;
}

const UpdateUserPassword = ({ setShowUpdatePassword }: any) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPasswordShow, setCurrentPasswordShow] = useState<boolean>(false);
  const [newPasswordShow, setNewPasswordShow] = useState<boolean>(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState<boolean>(false);
  const loggedInUserName = getLoginUserID()
  const navigate = useNavigate()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    switch (name) {
      case "currentPassword":
        setCurrentPassword(value);
        break;
      case "newPassword":
        setNewPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };

  const submitPasswordHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newErrors: { [key: string]: string } = {};

    if (!isNotEmptyString(currentPassword)) {
      newErrors.currentPassword = ErrorMessages.CurrentPasswordRequired;
    }

    if (!isNotEmptyString(newPassword)) {
      newErrors.newPassword = ErrorMessages.NewPasswordUpdate;
    } else if (!isStringLengthExceeded(newPassword, 6)) {
      newErrors.newPassword = ErrorMessages.PasswordStrength6Characters;
    }
    else if (isStringLengthExceeded(newPassword, 21)) { // Check for maximum length
      newErrors.newPassword = ErrorMessages.PasswordMaxLengthExceeded;
    } else if (!isPasswordStrong(newPassword)) {
      newErrors.newPassword = ErrorMessages.PasswordStrengthPopup;
    }

    if (!isNotEmptyString(confirmPassword)) {
      newErrors.confirmPassword = ErrorMessages.ReEnterPasswordRequired;
    } else if (confirmPassword !== newPassword) {
      newErrors.confirmPassword = ErrorMessages.PasswordUnmatchError;
    } else if (currentPassword === newPassword) {
      newErrors.confirmPassword = ErrorMessages.SamePasswordError;
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const object = {
        oldPassword: currentPassword,
        userId: loggedInUserName.userId,
        newPassword: newPassword,
      };

      UpdatePassword(object).then((response: any) => {
        if (response.status == true) {
          showToastSuccess(response.message);
          setNewPassword('');
          setConfirmPassword('');
          setCurrentPassword('');
          setShowUpdatePassword(false)
          navigate('/signin')
        } else {
          showToastError(response.message);
        }
      }).catch((error: any) => {
        showToastError(ErrorMessages.SomethingWrong);
      });
    }
  };

  // Toggle password visibility
  const toggleNewPasswordVisibility = () => {
    setNewPasswordShow(!newPasswordShow);
  };

  const toggleCurrentPasswordVisibility = () => {
    setCurrentPasswordShow(!currentPasswordShow);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShow(!confirmPasswordShow);
  };

  // For password
  const handleKeyDowns = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ' ') {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="position-fixed  w-100 start-0 top-0 h-100 z-[99999]  ">
        <div className="d-flex align-items-center h-100 bg-dark justify-content-center bg-opacity-50 p-2">
          <div className="rounded-md bg-white  shadow-xxl border justify-content-center w-[400px]">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12  d-mb-mb-30 mb-1">
              <div className="w-100 p-3">
                <h4 className="text-dark mb-3 d-sm-mb-2 d-xs-text-center fw-bold">
                  Change Password
                </h4>
                <form onSubmit={submitPasswordHandler} className="w-100">
                  <div className=" d-flex justify-content-between flex-column">
                    <div>
                      <div className="form-group mb-3 position-relative">
                        <InputField
                          name="currentPassword"
                          label="Current Password"
                          value={currentPassword}
                          type={currentPasswordShow ? "text" : "password"}
                          placeholder="Enter Current Password"
                          onChange={handleChange}
                          error={errors.currentPassword}
                          onKeyDown={handleKeyDowns}
                        />
                        {currentPasswordShow ? (
                          <span className="position-absolute top-50 end-0 me-2 mt-1 cursor-pointer">
                            <FaEye onClick={toggleCurrentPasswordVisibility} />
                          </span>
                        ) : (
                          <span className="position-absolute top-50 end-0 me-2 mt-1 cursor-pointer">
                            <FaEyeSlash onClick={toggleCurrentPasswordVisibility} />
                          </span>
                        )}
                      </div>
                      <div className="form-group mb-3 password-hint-direction-left position-relative">
                        <InputField
                          name="newPassword"
                          label="New Password"
                          type={newPasswordShow ? "text" : "password"}
                          passwordStrength={8} // Adjust the password strength as needed
                          value={newPassword}
                          placeholder="Enter New Password"
                          onChange={handleChange}
                          error={errors.newPassword}
                          onKeyDown={handleKeyDowns}

                        />
                        {newPasswordShow ? (
                          <span className="position-absolute top-50 end-0 me-2 mt-1 cursor-pointer">
                            <FaEye onClick={toggleNewPasswordVisibility} />
                          </span>
                        ) : (
                          <span className="position-absolute top-50 end-0 me-2 mt-1 cursor-pointer">
                            <FaEyeSlash onClick={toggleNewPasswordVisibility} />
                          </span>
                        )}
                      </div>
                      <div className="form-group position-relative">
                        <InputField
                          name="confirmPassword"
                          label="Confirm Password"
                          type={confirmPasswordShow ? "text" : "password"}
                          value={confirmPassword}
                          placeholder="Enter Confirm Password"
                          onChange={handleChange}
                          error={errors.confirmPassword}
                          onKeyDown={handleKeyDowns}
                        />
                        {confirmPasswordShow ? (
                          <span className="position-absolute top-50 end-0 me-2 mt-1 cursor-pointer">
                            <FaEye onClick={toggleConfirmPasswordVisibility} />
                          </span>
                        ) : (
                          <span className="position-absolute top-50 end-0 me-2 mt-1 cursor-pointer">
                            <FaEyeSlash onClick={toggleConfirmPasswordVisibility} />
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mb-0 mt-[29px]">
                      <button
                        className="btn btn-primary-outline me-1 w-[138px]"
                        type="button" onClick={() => setShowUpdatePassword(false)}
                      >
                        Cancel
                      </button>
                      <button className="btn btn-primary font-size-14 d-xs-w-100 text-white  w-[138px]" type="submit">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default UpdateUserPassword;
